import React, { useState } from 'react'
import down_arrow from '../assets/icons/select_downarrow.svg';
import { getPayloadForTestAptFaucet, getPayloadForTestUsdcFaucet } from '@kanalabs/trade';
import { useAptosContext } from '../providers/AptosWalletContext';
import { toast } from 'react-toastify';
import { useStore } from '../store';
import { client } from '../utils/Constants';
import ClickAwayListener from 'react-click-away-listener';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import { handleSignWeb3AuthTransaction } from '../utils/helper';
import NewMessageNotification from '../components/NewMessageNotification';
const faucetOption = [
    {
        item: 'eAPT',
        selected: false
    },
    {
        item: 'eUSDc',
        selected: false
    },
]
const FaucetDropdownMenu = () => {
    const { signAndSubmitTransaction, connected, wallet, disconnect }: any = useAptosContext();
    const { updateIsLoading, isWalletConnected, sdk, address, isLoading: isLoadingFromStore } = useStore()
    const [isFaucetSelected, setIsFaucetSelected] = useState(false);
    const [dropdownValues, setDropdownValues] = useState(faucetOption);

    const handleFaucet = () => {
        if (isLoadingFromStore) return
        setIsFaucetSelected(!isFaucetSelected);
    }

    const handleBaseFaucet = async () => {
        try {
            toast.dismiss()
            const payload = await getPayloadForTestAptFaucet("10000000000");
            let hash = ""
            let res = ""
            if (connected) {
                const hashResponse = await signAndSubmitTransaction(payload);
                await client.waitForTransaction(hashResponse.hash)
                hash = hashResponse.hash
                const response = await client.getTransactionByHash(hash);
                res = response.vm_status;
            } else if (isWalletConnected) {
                const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
                if (error) {
                    return toast(<NewMessageNotification link={transactionHash} type={"error"} message={`Transaction Failed`} />);
                }
                hash = transactionHash
                res = status;
            }
            if (res === 'Executed successfully') {
                return toast(<NewMessageNotification link={hash} type={"success"} message={`Base Coin Faucet Added`} />);
            } else {
                return toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`} />);
            }
        } catch (e: any) {
            if (e.name === 'Rejected') {
                return toast(<NewMessageNotification link={"none"} type={"error"} message={`User Rejected`} />);
            }
            return toast(<NewMessageNotification link={"none"} type={"error"} message={`Transaction Failed`} />);
        }
    }

    const handleQuoteFaucet = async () => {
        try {
            toast.dismiss()
            const payload = await getPayloadForTestUsdcFaucet("600000000");
            let hash = ""
            let res = ""
            if (connected) {
                const hashResponse = await signAndSubmitTransaction(payload);
                await client.waitForTransaction(hashResponse.hash)
                hash = hashResponse.hash
                const response = await client.getTransactionByHash(hash);
                res = response.vm_status;
            } else if (isWalletConnected) {
                const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
                if (error) {
                    return toast(<NewMessageNotification link={transactionHash} type={"error"} message={`Transaction Failed`} />);
                }
                hash = transactionHash
                res = status;
            }
            if (res === 'Executed successfully') {
                return toast(<NewMessageNotification link={hash} type={"success"} message={`Quote Coin Faucet Added!`} />);
            } else {
                return toast(<NewMessageNotification link={hash} type={"error"} message={`Please try again`} />);
            }

        } catch (e: any) {
            if (e.name === 'Rejected') {
                return toast(<NewMessageNotification link={"none"} type={"error"} message={`User Rejected`} />);
            }
        }
    }

    const handleFaucetOption = async (coinType: any) => {
        if (isLoadingFromStore) return
        toast.dismiss()
        if (connected || isWalletConnected) {
            if (wallet && wallet?.adapter?.network?.name !== 'Testnet') {
                toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Switch Network From Mainnet To Testnet in ${wallet.adapter.name} extension and try again!`} />);
                disconnect()
                setIsFaucetSelected(!isFaucetSelected);
                return
            }
            const newArray = [...dropdownValues]
            updateIsLoading(true)
            newArray.find((item) => item.item === coinType.item ? item.selected = true : item.selected = false)
            setDropdownValues(newArray)
            if (coinType.item === 'eAPT') {
                await handleBaseFaucet()
                const newArray = [...dropdownValues]
                newArray.find((item) => item.item === coinType.item ? item.selected = false : item.selected = false)
                setDropdownValues(newArray)
                setIsFaucetSelected(!isFaucetSelected);
            } else {
                await handleQuoteFaucet();
                const newArray = [...dropdownValues]
                newArray.find((item) => item.item === coinType.item ? item.selected = false : item.selected = false)
                setDropdownValues(newArray)
                setIsFaucetSelected(!isFaucetSelected);
            }
            updateIsLoading(false)
        } else {
            toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Connect Wallet`} />);
        }
        setIsFaucetSelected(!isFaucetSelected);
    }
    const handleClickAway = () => {
        isFaucetSelected && setIsFaucetSelected(false);
    }
    return (
        <div className='w-full'>
            <div
                className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} trade_select !bg-[rgba(255,255,255,0.06)] rounded-[2rem] cursor-pointer flex flex-row justify-between items-center  w-full `}
                onClick={handleFaucet}
            >
                <div className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} h-[4rem] !bg-transparent  text-[1rem] !shadow-none !font-inter flex flex-row gap-6   items-center justify-start  px-6 py-1  !w-full text-[white] font-[700]`}>
                    Faucet
                </div>
                <div className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} pr-5 !bg-transparent`}>
                    <img src={down_arrow} alt="down arrow" className='!bg-transparent' />
                </div>
            </div>
            {isFaucetSelected && (
                <ClickAwayListener onClickAway={handleClickAway}  >
                    <div className='!bg-[#1D1E20]  !backdrop-blur-2xl !fixed !z-[999] mt-3 xxl:!w-[20%] bxl:!w-[22%] xl:!w-[20%] sxl:!w-[20%] lg:!w-[27%]  md:!w-[84%]  sm:!w-[84%] xd:!w-[80%]  rounded-[2rem] '>
                        {dropdownValues && dropdownValues.map((item, key) => {
                            return <div key={key}
                                onClick={() => handleFaucetOption(item)}
                                className="h-[4rem]  !bg-transparent   cursor-pointer  !font-inter flex flex-row gap-6   items-center justify-start  px-6 py-1  !w-full hover:!bg-[rgba(0,249,169,0.1)]  hover:rounded-[2rem] "
                            >
                                <div className=" flex flex-row !bg-transparent justify-start !items-center w-full  ">
                                    <span className="text-base !bg-transparent text-white-A700 w-full xxl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1 xd:py-1 flex flex-row justify-between items-center">
                                        {item.item}
                                        {item.selected && (
                                            <Lottie
                                                loop
                                                animationData={Kanaloader}
                                                play
                                                className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                                            />
                                        )}
                                    </span>
                                </div>
                            </div>
                        })}
                    </div>
                </ClickAwayListener>)
            }

        </div>
    )
}

export default FaucetDropdownMenu
