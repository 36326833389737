/* eslint-disable no-self-assign */
import React, { useEffect, useState } from 'react';
import { List } from '../components';
import { useTranslation } from 'react-i18next';
import { useStore } from '../store';
import { TradeTable,toDecimalPrice, toDecimalSize } from '@kanalabs/trade';
import BigNumber from 'bignumber.js';
import { isObjectNonEmpty } from '../utils/helper';
import red_arrow from '../assets/icons/red_download.svg';
import Green_arrow from '../assets/icons/green_up_arrow.svg';
const OrderBook = () => {
  const { t } = useTranslation();
  const { marketDataInstance, orderBook, globalTrades } = useStore();
  const [orderBookEntries, setOrderBookEntries] = useState<any>();
  const [arrayOfAsks, setArrayOfAsks] = useState<any>();
  const [arrayOfBids, setArrayOfBids] = useState<any>();
  const [pairInfo, setPairInfo] = useState<any>();
  const [lastUpdated, setLastUpdated] = useState<any>();
  const [lastPlacedTrade, setLastPlacedTrade] = useState({
    price: "",
    isIncreased: false,
    previousTrade: ""
  })

  const handleGetTotalSumForAsk = async (sortedAsks: any) => {
    const sumValidation = [...sortedAsks]
    sumValidation?.reverse()?.map((ask: any, key: number) => {
      if (sumValidation.length - 1 < key) return
      if (key === 0) {
        const sumSize = parseFloat(ask.uiSize.toString())
        ask.sumSize = sumSize
      }
      else {
        const sumSize = parseFloat(ask.uiSize.toString()) + parseFloat(sumValidation[key - 1].sumSize)
        ask.sumSize = sumSize
      }
    })
    return sumValidation?.reverse()
  }

  const handleGetTotalSumForBid = async (sortedBids: any) => {
    const sumValidation = [...sortedBids]
    sumValidation?.reverse().map((bid: any, key: number) => {
      if (sumValidation.length - 1 < key) return
      if (key === 0) {
        const sumSize = parseFloat(bid.uiSize.toString())
        bid.sumSize = sumSize
      }
      else {
        const sumSize = parseFloat(sumValidation[key - 1].sumSize) + parseFloat(bid.uiSize.toString())
        bid.sumSize = sumSize
      }
    })
    return sumValidation
  }
  const handleGetCalculatedSizeAndPrice = (sortedElements: any, pairInfo: any) => {
    sortedElements.map((value: any) => {
      const uiSize: any = toDecimalSize({
        size: new BigNumber(value.remaining_size),
        lotSize: marketDataInstance.market.lotSize,
        baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
      })
      const uiPrice: any = toDecimalPrice({
        price: new BigNumber(value.price),
        lotSize: marketDataInstance.market.lotSize,
        tickSize: marketDataInstance.market.tickSize,
        baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
        quoteCoinDecimals: new BigNumber(pairInfo.quoteCoinInfo.decimals),
      })
      const numString = Math.abs(uiSize.toNumber()).toString();
      const decimalIndex = numString.indexOf('.');
      const colourGraphwidth = decimalIndex === -1 ? numString.length : decimalIndex;
      let result = 0;
      if (colourGraphwidth === 1) {
        const zeros = 5 > 0 && '0'.repeat(5);
        result = parseInt(`${colourGraphwidth}${zeros}`);
      } else if (colourGraphwidth === 2) {
        const zeros = 4 > 0 && '0'.repeat(4);
        result = parseInt(`${colourGraphwidth}${zeros}`);
      } else {
        result = uiSize.toNumber()
      }
      value.uiSize = uiSize;
      value.uiPrice = uiPrice;
      value.graphSize = result;
    })
    return sortedElements;
  }

  const handleValidatePriceAndSize = (elements: any) => {
    const sortedElements = [...elements].sort((a, b) => a.priceFloat === b.priceFloat ? a.sizeFloat = (b.sizeFloat + a.sizeFloat) : a.sizeFloat = a.sizeFloat);
    const pushValue: any[] = [];
    for (let i = 0; i < sortedElements.length; i++) {
      if (i === sortedElements.length && sortedElements[i].priceFloat === sortedElements[i - 1].priceFloat) {
        console.log()
      }
      if (sortedElements[i].priceFloat === sortedElements[i + 1]?.priceFloat) {
        console.log()
      }
      else {
        pushValue.push(sortedElements[i])
      }
    }
    return pushValue
  }

  const handleGetOrderBookDetails = async () => {
    if (isObjectNonEmpty(marketDataInstance)) {
      const getPairDetailsResponse = await marketDataInstance?.getTradePairInfo();
      setPairInfo(getPairDetailsResponse)
      if (isObjectNonEmpty(orderBook) && isObjectNonEmpty(getPairDetailsResponse)) {
        setLastUpdated(orderBook.lastUpdated)
        orderBook?.data.asks.map((item: any) => {
          item.priceFloat = parseFloat(item.price)
          item.sizeFloat = parseFloat(item.remaining_size)
        })
        orderBook?.data.bids.map((item: any) => {
          item.priceFloat = parseFloat(item.price)
          item.sizeFloat = parseFloat(item.remaining_size)
        })
        const sortedAsksBeforeRevers = [...orderBook.data.asks].sort((a, b) => b.priceFloat - a.priceFloat);
        const sortedElementsAsks = handleGetCalculatedSizeAndPrice(sortedAsksBeforeRevers, getPairDetailsResponse)
        setArrayOfAsks(sortedElementsAsks);
        const sortedBids = [...orderBook.data.bids].sort((a, b) => a.priceFloat - b.priceFloat);
        const sortedElementsBids = handleGetCalculatedSizeAndPrice(sortedBids, getPairDetailsResponse)
        setArrayOfBids(sortedElementsBids);
        setOrderBookEntries(orderBook.data)
        const ask = handleValidatePriceAndSize(sortedElementsAsks)
        const sumValidationAsks = await handleGetTotalSumForAsk(ask)
        setArrayOfAsks(sumValidationAsks)
        const bid = handleValidatePriceAndSize(sortedElementsBids)
        const sumValidationBids = await handleGetTotalSumForBid(bid)
        setArrayOfBids(sumValidationBids)
        const uniqueSet = new Set();
        const result: TradeTable[] = [];
        globalTrades?.data?.forEach((obj: any) => {
          const propertyValue = obj['txn_version'];
          if (!uniqueSet.has(propertyValue)) {
            uniqueSet.add(propertyValue);
            result.push(obj);
          }
        });
        const sortedTrades: any = [...result].sort((a, b) => b.txn_version - a.txn_version);
        const lastTrade = toDecimalPrice({
          price: new BigNumber(sortedTrades[0]?.price),
          lotSize: marketDataInstance.market.lotSize,
          tickSize: marketDataInstance.market.tickSize,
          baseCoinDecimals: new BigNumber(getPairDetailsResponse.baseCoinInfo.decimals),
          quoteCoinDecimals: new BigNumber(getPairDetailsResponse.quoteCoinInfo.decimals),
        }).toString()
        const lastBeforeOne = toDecimalPrice({
          price: new BigNumber(sortedTrades[1]?.price),
          lotSize: marketDataInstance.market.lotSize,
          tickSize: marketDataInstance.market.tickSize,
          baseCoinDecimals: new BigNumber(getPairDetailsResponse.baseCoinInfo.decimals),
          quoteCoinDecimals: new BigNumber(getPairDetailsResponse.quoteCoinInfo.decimals),
        }).toString()
        const lastPlacedTradeStruct = { ...lastPlacedTrade }
        lastPlacedTradeStruct.price = lastTrade
        lastPlacedTradeStruct.previousTrade = lastBeforeOne
        lastPlacedTradeStruct.isIncreased = lastTrade > lastBeforeOne ? true : lastTrade === lastBeforeOne ? true : false
        setLastPlacedTrade(lastPlacedTradeStruct)
      }
    }
  }

  useEffect(() => {
    handleGetOrderBookDetails()
  }, [orderBook])

  const handleRefresh = () => {
    handleGetOrderBookDetails()
  }

  return (
    <>
      <div className="  z-[0] flex-col gap-4   !w-full flex lg:px-[1rem] md:px-[1rem] sm:px-[1rem] xd:px-[1rem]">
        <div className="  flex flex-col   items-start justify-start   rounded-3xl w-full ">
          <div className=" !bg-transparent flex  flex-row gap-4 items-center justify-between w-full pt-2">
            <div className="text-[14px]  !bg-transparent  font-inter font-[700] text-[#777879] w-full">{t('order_books')}</div>
            {/* <div className="flex flex-row justify-between items-center  !bg-transparent ">
              <img className="h-4 cursor-pointer" src={refresh} alt="refresh" onClick={() => handleRefresh()} />
            </div> */}
          </div>
          <br />
          <div className="flex flex-col gap-0.5 items-center justify-center w-full">
            <div className="flex flex-row   font-manrope items-center justify-between w-full ">
              <div className="  font-[700] text-[10px] text-white-A700_cc  text-left flex-[0.2]">{t('price()')}</div>
              <div className="  font-[700] text-[10px] text-white-A700_cc  text-right flex-[0.4]">{t('size()')}</div>
              <div className="text-[10px] font-[700] text-white-A700_cc   text-right flex-[0.4]">{t('sum()')}</div>
            </div>
            <List className="flex flex-col gap-0.5 items-center w-full" orientation="vertical">
              {orderBookEntries && arrayOfAsks && arrayOfAsks?.reverse()?.map((ask: any, key: number) => {
                const length = (arrayOfAsks.length > 10 ? 4 : 0);
                if (key <= length) return
                return <div key={key} className="flex  flex-row   items-center justify-between w-full">
                  <div className={`flex flex-row items-center justify-between`} style={{ maxWidth: '100%', backgroundColor: `rgba(255,85,85,0.20)`, width: `100%`, background: `linear-gradient(to right, transparent ${100 - (ask.uiSize.toString() / 100)}%, rgba(255,85,85,0.20) ${100 - (ask.uiSize.toString() / 100)}%)`, backgroundSize: '100% 100%' }}>
                    <div className="xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem] !text-left font-[400] text-[rgba(255,255,255,0.60)]  flex-[0.2]">
                      {parseFloat(ask?.uiPrice)?.toFixed(3)}
                    </div>
                    <div className="xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  !text-right  font-[400] text-[rgba(255,255,255,0.60)]  flex-[0.4]">
                      {parseFloat(ask?.uiSize)?.toFixed(3)}
                    </div>
                    <div className="xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  !text-right  font-[400] text-[rgba(255,255,255,0.60)]  flex-[0.4]">
                      {ask?.sumSize?.toFixed(3)}
                    </div>
                  </div>
                </div>
              })}
            </List>
            <div className="flex flex-row gap-[8px] items-center justify-start py-2 w-full">
              <div className={`text-[18px] font-manrope ${lastPlacedTrade.isIncreased ? 'text-[#12B76A]' : 'text-[#F97066]'}  w-auto font-[400] text-left flex flex-row justify-start items-center gap-[8px]`}>
                {lastPlacedTrade.price !== "NaN" && lastPlacedTrade?.price}
              </div>
              <div>
                {lastPlacedTrade.price !== "NaN" && lastPlacedTrade?.isIncreased ? <img src={Green_arrow} alt='increased' /> : <img src={red_arrow} alt='decreased' />}
              </div>
            </div>
            <List className="flex flex-col gap-0.5 items-center w-full" orientation="vertical">
              {orderBookEntries && arrayOfBids?.map((bid: any, key: number) => {
                if (key > 7) return
                if (!pairInfo?.baseCoinInfo || !pairInfo?.quoteCoinInfo) return null;
                return <div key={key} className="flex  flex-row   !items-center !justify-between !w-full">
                  <div className={`flex flex-row items-center justify-between`} style={{ maxWidth: '100%', backgroundColor: `rgba(0,249,169,0.20)`, width: `100%`, background: `linear-gradient(to right, transparent ${100 - (bid.uiSize.toString() / 100)}%, rgba(0,249,169,0.20) ${100 - (bid.uiSize.toString() / 100)}%)`, backgroundSize: '100% 100%' }}>
                    <div className="!text-left xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem] font-[400] text-[rgba(255,255,255,0.60)]  flex-[0.2]">
                      {parseFloat(bid?.uiPrice)?.toFixed(3)}
                    </div>
                    <div className="!text-right xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  font-[400] text-[rgba(255,255,255,0.60)]  flex-[0.4]">
                      {parseFloat(bid?.uiSize)?.toFixed(3)}
                    </div>
                    <div className="text-right xxl:text-[0.75rem] bxl:text-[0.75rem] xl:text-[0.75rem] sxl:text-[0.75rem] lg:text-[0.625rem] md:text-[0.625rem] sm:text-[0.625rem]  xd:text-[0.625rem]  font-[400] text-[rgba(255,255,255,0.60)]  flex-[0.4]">
                      {bid?.sumSize?.toFixed(3)}
                    </div>
                  </div>
                </div>
              })};
            </List>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderBook;