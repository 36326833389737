import React from 'react';
import Lottie from 'react-lottie-player';
import Kanaloader from '../../assets/kanaloader.json';
const Loader = () => {
    return (
        <div className="fixed inset-0 !z-[3]  backdrop-blur-xl  font-inter  items-center overflow-y-auto !bg-transparent h-[100%] w-[100%] flex flex-col justify-start   rounded-[2.25rem]  shadow-[0rem_2.5rem_2rem_-3.313rem_rgba(0,0,0,0.06)] ">
            <div className="flex justify-center align-middle pt-60 !bg-transparent">
                <Lottie className='!bg-transparent' loop animationData={Kanaloader} play style={{ width: 150, height: 150 }} />
            </div>
            <div className="!bg-transparent text-center text-[0.875rem] text-[#ffffff99] align-middle 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-10 sm:pt-4 max-sm:pt-4">
                This may take few minutes, don’t close the window
            </div>
        </div>
    );
};

export default Loader;
