import React, { useEffect, useState } from 'react';
import { Button, List } from '../components';
import close from '../assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import down_arrow from '../assets/icons/select_downarrow.svg';
import aptos from '../assets/icons/AptosLogo.svg';
import usdc from '../assets/icons/usdc.svg';
import { useAptosContext } from '../providers/AptosWalletContext';
import { useStore } from '../store';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import { toast } from 'react-toastify';
import {
  getPercentageAmount,
  getUiAmount,
  getUiAmountReverse,
  handleSignWeb3AuthTransaction,
  onTokenAmountChange,
  preventPasteNegativeNumber,
} from '../utils/helper';
import { client, percentageOptions } from '../utils/Constants';
import ClickAwayListener from 'react-click-away-listener';
import NewMessageNotification from '../components/NewMessageNotification';

interface withDrawInterface {
  handleCloseModal(value: boolean): void;
  handleDepositModal(value: boolean): void;
}

const WithdrawModel = (props: withDrawInterface) => {
  const { handleCloseModal, handleDepositModal } = props;
  const { t } = useTranslation();
  const { signAndSubmitTransaction, connected, account }: any = useAptosContext();
  const { marketDataInstance, balanceUpdate, updateBalanceUpdate, updateIsLoading, isLoading: isLoadingFromStore, isWalletConnected, address: socialAddress, sdk } = useStore();
  const [dropdownValues, updateDropdownValues] = useState<any>([
    {
      item: 'eAPT',
      selected: false,
      icon: aptos,
      balance: 0,
      decimals: 0,
      marketAccountBalance: 0,
      marketAccountDecimals: 0
    },
    {
      item: 'eUSDc',
      selected: false,
      icon: usdc,
      balance: 0,
      decimals: 0,
      marketAccountBalance: 0,
      marketAccountDecimals: 0
    },
  ]);
  const [selectedCoin, setSelectedCoin] = useState<any>(dropdownValues[0]);
  const [withdrawAmount, setWithDrawAmount] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);
  const [tradePairInfo, setTradePairInfo] = useState<any>();

  const handleGetAccountBalance = async (tradePairInfoResponse: any) => {
    const resources = await client.getAccountResources(connected ? account.address : socialAddress)
    const coinStoreType = '0x1::coin::CoinStore';
    const coinResources = resources.filter((r: any) => r.type.startsWith(coinStoreType));
    const newArray: any = []
    coinResources?.map(async (res: any) => {
      if (res.type === ("0x1::coin::CoinStore<0xc0de11113b427d35ece1d8991865a941c0578b0f349acabbe9753863c24109ff::example_apt::ExampleAPT>")) {
        res.cointype = 'eAPT'
        res.balance = getUiAmountReverse(res?.data?.coin?.value, tradePairInfoResponse?.baseCoinInfo?.decimals)
        newArray.push(res)
      } else
        if (res.type === ("0x1::coin::CoinStore<0xc0de11113b427d35ece1d8991865a941c0578b0f349acabbe9753863c24109ff::example_usdc::ExampleUSDC>")) {
          res.cointype = 'eUSDC'
          res.balance = getUiAmountReverse(res?.data?.coin?.value, tradePairInfoResponse?.quoteCoinInfo?.decimals)
          newArray.push(res)
        }
    })
    const updateBalanceInCoinInfo = [...dropdownValues];
    updateBalanceInCoinInfo.map((coin: any) => {
      if (coin.item === 'eAPT') {
        coin.marketAccountBalance = coin.marketAccountBalance !== 0 ? coin.marketAccountBalance : 0
        coin.marketAccountDecimals = tradePairInfoResponse?.baseCoinInfo?.decimals;
        coin.balance = newArray[0].cointype === 'eAPT' ? newArray[0].balance : newArray[1].balance;
      } else {
        coin.marketAccountBalance = coin.marketAccountBalance !== 0 ? coin.marketAccountBalance : 0
        coin.marketAccountDecimals = tradePairInfoResponse?.quoteCoinInfo?.decimals;
        coin.balance = newArray[1].cointype === 'eUSDC' ? newArray[1].balance : newArray[0].balance;
      }
    })
    return newArray
  }

  const handleGetUserInfo = async () => {
    try {
      if (connected || isWalletConnected) {
        const tradePairInfoResponse = await marketDataInstance.getTradePairInfo();
        setTradePairInfo(tradePairInfoResponse);
        const newArray = await handleGetAccountBalance(tradePairInfoResponse)
        if (newArray) {
          const marketInfo = await marketDataInstance.getUserMarketAccount(connected ? account.address : socialAddress);
          if (marketInfo?.length > 0) {
            const userInfoBaseBalance = getUiAmountReverse(
              marketInfo[0]?.base_available,
              tradePairInfoResponse?.baseCoinInfo?.decimals
            );
            const userInfoQuoteBalance = getUiAmountReverse(
              marketInfo[0]?.quote_available,
              tradePairInfoResponse?.quoteCoinInfo?.decimals
            );
            const updateBalanceInCoinInfo = [...dropdownValues];
            updateBalanceInCoinInfo.map((coin: any) => {
              if (coin.item === 'eAPT') {
                coin.marketAccountBalance = userInfoBaseBalance;
                coin.marketAccountDecimals = tradePairInfoResponse?.baseCoinInfo?.decimals;
                coin.balance = newArray[0].cointype === 'eAPT' ? newArray[0].balance : newArray[1].balance;
              } else {
                coin.marketAccountBalance = userInfoQuoteBalance;
                coin.marketAccountDecimals = tradePairInfoResponse?.quoteCoinInfo?.decimals;
                coin.balance = newArray[1].cointype === 'eUSDC' ? newArray[1].balance : newArray[0].balance;
              }
            });
            updateDropdownValues(updateBalanceInCoinInfo);
            updateBalanceUpdate(false)
          }
        }

      }
    }
    catch (e) {
      updateBalanceUpdate(false)
    }
  };

  useEffect(() => {
    handleGetUserInfo();
  }, [marketDataInstance, connected, socialAddress, balanceUpdate]);

  const handleDeposit = () => {
    if (isLoadingFromStore) return
    handleDepositModal(true);
  };

  const handleWithdrawDropdown = () => {
    if(isLoadingFromStore) return
    setIsOpenWithdraw(!isOpenWithdraw);
  };

  const handleSelectedToken = (token: any) => {
    if(isLoadingFromStore) return
    setSelectedCoin(token);
    setWithDrawAmount('')
    setIsOpenWithdraw(!isOpenWithdraw);
  };

  const handleWithdrawBaseCoin = async (tradePairInfo: any) => {
    try {
      toast.dismiss()
      const uiAmount = getUiAmount(withdrawAmount, tradePairInfo.baseCoinInfo.decimals);
      const payload = marketDataInstance.withdrawBaseCoinPayload(uiAmount.toString());
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload);
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          return toast(<NewMessageNotification link={transactionHash} type={"error"} message={`Transaction Failed`}/>);
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed Successfully`}/>);
        handleGetUserInfo();
        updateBalanceUpdate(true)
      } else {
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`}/>);
     
      }
    } catch (e) {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Transaction Failed`}/>);
     
    }
  };

  const handleWithdrawQuoteCoin = async (tradePairInfo: any) => {
    try {
      toast.dismiss()
      const uiAmount = getUiAmount(withdrawAmount, tradePairInfo.quoteCoinInfo.decimals);
      const payload = marketDataInstance.withdrawQuoteCoinPayload(uiAmount.toString());
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload);
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          return toast(<NewMessageNotification link={transactionHash} type={"error"} message={`Transaction Failed`}/>);
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed Successfully`}/>);
        handleGetUserInfo();
        updateBalanceUpdate(true)
      } else {
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`}/>);
      }
    } catch (e) {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Transaction Failed`}/>);
    }
  };

  const handleWithdraw = async () => {
    if (isLoadingFromStore) return;
    toast.dismiss();
    setIsLoading(true);
    if (withdrawAmount === null || withdrawAmount === undefined || withdrawAmount === 0) {
      setIsLoading(false);
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Enter Number Of Coins To Withdraw`}/>);
      return;
    }
    if (withdrawAmount >= selectedCoin.marketAccountBalance) {
      setIsLoading(false);
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Insufficient Coins To Withdraw`}/>);
      setWithDrawAmount('');
      return;
    }
    if (selectedCoin && selectedCoin.item === 'eAPT') {
      await handleWithdrawBaseCoin(tradePairInfo);
      setWithDrawAmount('');
      setIsLoading(false);
    } else {
      await handleWithdrawQuoteCoin(tradePairInfo);
      setWithDrawAmount('');
      setIsLoading(false);
    }
  };

  const handleClickAway = () => {
    isOpenWithdraw && setIsOpenWithdraw(false);
  };

  const handleGetPercentageAmount = (percentage: any) => {
    if (isLoadingFromStore) return;
    if (selectedCoin.marketAccountBalance === 0) return;
    const amount = getPercentageAmount(percentage, selectedCoin.marketAccountBalance, selectedCoin.marketAccountDecimals);
    setWithDrawAmount(amount);
  };

  const handleEnteredValueForDeposit = (enteredValue: any) => {
    if(isLoadingFromStore) return
    if (enteredValue?.length > 8) {
      return
    }
    const validatedValue = onTokenAmountChange(enteredValue);
    setWithDrawAmount(validatedValue);
  };

  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <div className="!font-inter fixed inset-0 z-[24] xxl:pt-24 xl:pt-24 lg:pt-24 md:pt-24 sm:!pt-0 max-h-[full] flex flex-row justify-center items-center align-middle backdrop-blur-[1rem] overflow-y-auto bg-transparent sm:w-full md:w-full">
        <div className="border-2 border-solid  xxl:border bxl:border-[#2e2e2e] xl:border-[#2e2e2e] sxl:border-[#2e2e2e] lg:border-[#2e2e2e] md:border-[#2e2e2e] sm:!border-none xd:!border-none rounded-[2.25rem] bg-transparent">
          <div className="bg-transparent   flex flex-col gap-4 items-center justify-start  md:px-5  sm:py-4 xd:py-4 rounded-[2.25rem] min-w-[20rem] sm:w-full !backdrop-blur-[2.5rem]">
            <div className="p-[20px_24px] flex rounded-[2.25rem_2.25rem_0rem_0rem] flex-row sm:flex-row  gap-6 items-start bg-[rgba(255,255,255,0.06)] justify-between w-full">
              <div className="text-2xl font-[700] md:text-[1.375rem] text-white-A700 sm:text-xl w-auto bg-transparent">Withdraw </div>
              <div className="flex flex-row gap-2 items-center justify-start w-auto bg-transparent">
                <Button
                  className={`border-teal-A400_19 border-[2px] bg-transparent ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} font-bold flex flex-row justify-center items-center leading-[normal]  px-[1rem] py-[0.5rem] rounded-lg text-center text-cyan-A200 text-sm`}
                  onClick={handleDeposit}
                >
                  Deposit
                </Button>
                <img
                  className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}  h-5 w-5 `}
                  src={close}
                  alt="close"
                  onClick={() => { if (isLoadingFromStore) { return 0 } handleCloseModal(true) }}
                />
              </div>
            </div>
            <List className="flex flex-col gap-4 items-center w-full px-8 py-4" orientation="vertical">
              <div className="flex sm:flex-1 flex-col items-start justify-start w-auto sm:w-full">
                <div className="flex flex-col items-start justify-start w-[31rem] sm:w-full">
                  <div className="bg-[#000000] flex flex-col gap-[0.5rem] items-center justify-start sm:px-5 px-6 py-4 rounded-[1.5rem] w-full">
                    <div className="flex sm:flex-col flex-row gap-4 h-[4.5rem] md:h-auto items-center justify-start w-full">
                      <div
                        className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} bg-white-A700_19 flex flex-1 flex-row h-full items-center justify-between sm:px-5 px-6 py-2 rounded-[1rem] w-full`}
                        onClick={handleWithdrawDropdown}
                      >
                        <div className="flex flex-row gap-2 py-2 items-center justify-start w-auto">
                          <div className="flex flex-col h-10 items-center justify-start w-10">
                            <div className="h-10 md:h-7  pt-1.5 relative rounded-[50%] w-10">
                              <img
                                className="absolute h-7 inset-[0] justify-center m-auto object-cover w-[65%]"
                                src={selectedCoin?.icon}
                                alt="popup_token"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col items-start justify-center w-auto">
                            <div className="flex flex-col items-start justify-center w-auto">
                              <div className="text-white-A700 text-xl w-auto">{selectedCoin?.item}</div>
                            </div>
                          </div>
                        </div>
                        <img src={down_arrow} alt="dropdown" />
                      </div>
                      {isOpenWithdraw && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <div className="bg-[#1D1E20] !backdrop-blur-2xl !fixed !z-[999] mt-52 xxl:!w-[28rem] bxl:!w-[28rem] xl:!w-[28rem] sxl:!w-[28rem] lg:!w-[96%]  md:!w-[95%]  sm:!w-[90%] xd:!w-[88%]  rounded-[1rem] ">
                            {dropdownValues &&
                              dropdownValues.map((asset: any, key: number) => {
                                return (
                                  <div key={key}>
                                    <div
                                      onClick={() => handleSelectedToken(asset)}
                                      className={`h-[4rem] ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}!font-inter flex flex-row gap-6   items-center justify-start  px-6 py-1  !w-full hover:bg-[rgba(0,249,169,0.1)]  hover:rounded-[1rem]`}
                                    >
                                      <div className="flex flex-row gap-2 py-2 items-center justify-start w-auto">
                                        <div className="flex flex-col h-10 items-center justify-start w-10">
                                          <div className="h-10 md:h-7  pt-1.5 relative rounded-[50%] w-10">
                                            <img
                                              className="absolute h-7 inset-[0] justify-center m-auto object-cover w-[65%]"
                                              src={asset?.icon}
                                              alt="popup_token"
                                            />
                                          </div>
                                        </div>
                                        <div className="flex flex-col items-start justify-center w-auto">
                                          <div className="flex flex-col items-start justify-center w-auto">
                                            <div className="text-white-A700 text-[1.125rem] w-auto">{asset?.item}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 h-full md:h-auto items-center justify-between w-full">
                      <div className="flex flex-row gap-2 items-center justify-between w-full border-solid border-[1px] p-[0.7rem_1rem] border-white-A700_33 rounded-[0.5rem]">
                        <div className="text-sm text-white-A700_cc w-auto text-left font-bold">Wallet balance:</div>
                        <div className="text-sm text-white-A700_cc w-auto text-right">
                          {selectedCoin ? selectedCoin?.balance?.toFixed(3) : 0}
                        </div>
                       </div>
                      <div className="flex flex-row gap-2 items-center justify-between w-full border-solid border-[1px] p-[0.7rem_1rem] border-white-A700_33 rounded-[0.5rem]">
                        <div className="text-sm text-white-A700_cc w-auto text-left font-bold">Trading balance:</div>
                        <div className="text-sm text-white-A700_cc w-auto text-right">
                          {selectedCoin ? selectedCoin?.marketAccountBalance?.toFixed(3) : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-1 flex-col items-start justify-start w-auto sm:w-full">
                <div className="flex flex-col items-start justify-start w-[31rem] sm:w-full">
                  <div className="bg-[#000000] flex flex-col gap-4 items-center justify-start sm:px-5 px-6 py-4 rounded-[1.5rem] w-full">
                    <div className="flex sm:flex-col flex-row gap-4 h-[4.5rem] md:h-auto items-center justify-start w-full">
                      <input
                        className="text-left font-[700] bg-transparent outline-none border-b border-solid border-white-A700_33 flex-1 h-full sm:pl-0   py-4 md:text-3xl sm:text-[28px] text-[32px] placeholder:text-white-A700_99 text-[white] w-full sm:!text-center"
                        placeholder="0"
                        min="0"
                        value={withdrawAmount}
                        disabled={isLoadingFromStore}
                        autoFocus={true}
                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                        onChange={(e) => handleEnteredValueForDeposit(e.target.value)}
                        onPaste={preventPasteNegativeNumber}
                      />
                    </div>
                    <div className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex flex-row gap-2 h-[35px] md:h-auto items-center justify-between w-full`}>
                      <div className="flex flex-row gap-2 items-start justify-start w-auto">
                        {percentageOptions &&
                          percentageOptions.map((option: any, key: number) => {
                            return (
                              <Button
                                key={key}
                                onClick={() => handleGetPercentageAmount(option)}
                                className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} ${option === 'clear'
                                  ? 'bg-[#121926]  border border-black-900_19 border-solid font-medium leading-[normal] min-w-[56px] py-2 rounded-lg text-center text-[#088AB2] text-[12px] font-manrope font-[400] tracking-[0.24px]'
                                  : 'bg-[#121926] border border-black-900_19 border-solid font-medium leading-[normal] min-w-[50px] py-2 rounded-lg text-center text-[#088AB2] text-[12px] font-manrope font-[400] tracking-[0.24px]'
                                  }`}
                              >
                                {option}
                              </Button>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </List>
            <div className='w-full px-8 pb-8'>
              <Button
                onClick={() => handleWithdraw()}
                className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'
                  }  buy-button w-full `}
              >
                <div className='buy-button-inner w-full py-[1rem] rounded-[1rem] flex flex-row justify-center items-center'>
                  
                  {isLoading ? (
                    // <ColorRing
                    //   visible={true}
                    //   height="25"
                    //   width="80"
                    //   ariaLabel="blocks-loading"
                    //   wrapperStyle={{}}
                    //   wrapperClass="blocks-wrapper"
                    //   colors={['#00000', '#00000', '#00F9A9', '#00000', '#00000']}
                    // />
                    <Lottie
                      loop
                      animationData={Kanaloader}
                      play
                      className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                    />
                  ): <span className='button-text font-[700]'>Transfer to Wallet</span>}
                </div>
              </Button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawModel;
