import { EconiaTrade } from "@kanalabs/trade";
import { AptosClient } from "aptos";

export const ASK = true;
export const BID = false;
export const SELL = true;
export const BUY = false; 
console.log("🚀 ~ file: Constants.tsx:9 ~ process.env.REACT_APP_APTOS_CLIENT_ID:", process.env.REACT_APP_APTOS_CLIENT_ID)
export const client: any = new AptosClient(process.env.REACT_APP_APTOS_CLIENT_ID as string);
export const econia = new EconiaTrade(client);


export const initialData = [
   
	{ time: '2018-12-24', value: 27.02 },
	{ time: '2018-12-25', value: 27.32 },
	{ time: '2018-12-26', value: 25.17 },
	{ time: '2018-12-27', value: 28.89 },
	{ time: '2018-12-28', value: 25.46 },
	{ time: '2018-12-29', value: 23.92 },
	{ time: '2018-12-30', value: 22.68 },
	{ time: '2018-12-31', value: 22.67 },
    {
        "time": "2023-09-04",
        "value": 2000
    },
    { time: '2023-09-20', value: 32.51 },
   
];

export const percentageOptions = ['25%', '50%', 'Max', 'Clear']