
import React from 'react'
const NewMessageNotification = (props: any) => {
  const { type, link, message } = props

  const handleOpenExplorer = () => {
    if(link === "none"){
      return
    }else{
      window.open(`https://explorer.aptoslabs.com/txn/${link}?network=testnet`, '_blank', 'noreferrer')
    }
  }
  return (
      <div onClick={()=>handleOpenExplorer()} className='flex flex-col justify-start items-start text-[0.8rem] font-[400] text-[white] gap-[0.5rem]'>
        <div className={`${type === 'error' ? ' border-[#B42318] ' : '  border-[#027A48] '
          } flex flex-row justify-start items-center gap-[0.5rem] border-l-2 px-[0.5rem] `}  >
          <div>{message}</div>
        </div>
      </div>
  );

}

export default NewMessageNotification