import { Button, List } from '../components';
import React, { useEffect, useState } from 'react';
import { useAptosContext } from '../providers/AptosWalletContext';
import { useStore } from '../store';
import useClock from '../hooks/useClock';
import { toast } from 'react-toastify';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import { getUiAmount, getUiAmountReverse, handleSignWeb3AuthTransaction, isObjectNonEmpty, onTokenAmountChange, preventPasteNegativeNumber } from '../utils/helper';
import { useTranslation } from 'react-i18next';
import { client } from '../utils/Constants';
import { BUY, SELL } from '@kanalabs/trade';
import NewMessageNotification from '../components/NewMessageNotification';
interface BuySellInterface {
  handleWalletConnect(): void;
}

const BuySell = (props: BuySellInterface) => {
  const { t } = useTranslation();
  const { handleWalletConnect } = props;
  const { marketDataInstance, updateNeedToUpdateGlobalTrades,needToUpdateGlobalTrades,  updateBalanceUpdate, balanceUpdate, updateIsLoading, isLoading: isLoadingFromStore, isWalletConnected, address: socialAddress, sdk } = useStore();
  const { connected, signAndSubmitTransaction, account, wallet, disconnect }: any = useAptosContext();
  const [enteredBaseValue, setEnteredBaseValue] = useState<any>();
  const [enteredQuoteValue, setEnteredQuoteValue] = useState<any>();
  const [totalValue, setTotalValue] = useState<any>('');
  const date = useClock();
  const [typeOfOrder, setTypeOfOrder] = useState<string>('market');
  const [typeOfRootOrder, setTypeOfRootOrder] = useState<string>('BUY');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tradePairInfo, setTradePairInfo] = useState<any>();
  const [userinfo, setUserInfo] = useState<any>();

  const handleGetUserInfo = async () => {
    if (connected || isWalletConnected) {
      try {
        const marketInfo = await marketDataInstance.getUserMarketAccount(connected ? account.address : socialAddress);
        if (marketInfo?.length > 0) {
          const user = marketInfo[0]
          const tradePairInfoResponse = await marketDataInstance.getTradePairInfo();
          const userInfoBaseBalance = getUiAmountReverse(
            user?.base_available,
            tradePairInfoResponse?.baseCoinInfo?.decimals
          );
          const userInfoQuoteBalance = getUiAmountReverse(
            user?.quote_available,
            tradePairInfoResponse?.quoteCoinInfo?.decimals
          );
          user.baseBalance = userInfoBaseBalance
          user.baseDecimals = tradePairInfoResponse?.baseCoinInfo?.decimals;
          user.quoteBalance = userInfoQuoteBalance
          user.quoteDecimals = tradePairInfoResponse?.quoteCoinInfo?.decimals;
          setUserInfo(user)
          setTradePairInfo(tradePairInfoResponse);
        }
        updateNeedToUpdateGlobalTrades(false);
        updateBalanceUpdate(false)
      } catch (e) {
        console.log("")
      }
    } else {
      updateNeedToUpdateGlobalTrades(false);
      updateBalanceUpdate(false)
      setUserInfo(undefined)
    }
  };

  useEffect(() => {
    handleGetUserInfo()
  }, [marketDataInstance, connected, balanceUpdate, isWalletConnected, needToUpdateGlobalTrades]);

  const handleValidationBeforePlacingOrder = async () => {
    toast.dismiss();
    if (connected && wallet && wallet?.adapter?.network?.name !== 'Testnet') {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Switch Network From Mainnet To Testnet in ${wallet.adapter.name} extension and try again`}/>);
      disconnect();
      return;
    }
    if (enteredQuoteValue == 0 && typeOfOrder == 'limit') {
      toast(<NewMessageNotification link={"tradebook.kanalabs.io"} type={"error"} message='Please Enter Limit Price'/>);
      return 0;
    }
    if (enteredBaseValue == 0) {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Enter Quantity you want to ${typeOfRootOrder}`}/>);
      return 0;
    } try {
      const marketInfo = await marketDataInstance.getUserMarketAccount(connected ? account.address : socialAddress);
      if (typeOfRootOrder === 'BUY') {
        const uiAmount = getUiAmount(
          typeOfOrder === 'market' ? totalValue : enteredQuoteValue,
          tradePairInfo.quoteCoinInfo.decimals
        );
        if (marketInfo[0].quote_available >= uiAmount) {
          return 1;
        } else {
          toast(<NewMessageNotification link={"none"} type={"error"} message={`Market Account does not have enough fund! Please Deposit`}/>);
          return 0;
        }
      } else {
        const uiAmount = getUiAmount(
          enteredBaseValue,
          tradePairInfo.baseCoinInfo.decimals
        );
        if (marketInfo[0].base_available >= uiAmount) {
          return 1;
        } else {
          toast(<NewMessageNotification link={"none"} type={"error"} message={`Market Account does not have enough fund! Please Deposit`}/>);
          return 0;
        }
      }
    } catch (e) {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Deposit Some Funds To Market Account`}/>);
      return 0;
    }

  };

  const handlePlaceMarketOrder = async () => {
    if (isLoadingFromStore) return;
    try {
      setIsLoading(true);
      updateIsLoading(true);
      const marketData = await handleValidationBeforePlacingOrder();
      if (marketData === 0) {
        setIsLoading(false);
        return;
      }
      const typeOfTransaction = typeOfRootOrder === 'BUY' ? BUY : SELL;
      const estimatedPrice = await marketDataInstance.getEstimatedPrice(enteredBaseValue, typeOfTransaction);
      const payload: any = await marketDataInstance.placeMarketOrder(typeOfTransaction);
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload);
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          setIsLoading(false);
          toast(<NewMessageNotification link={transactionHash} type={"error"} message={status}/>);
          return
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed successfully`}/>);
        setIsLoading(false);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
        updateNeedToUpdateGlobalTrades(true);
        handleGetUserInfo()
        return;
      } else {
        setIsLoading(false);
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`}/>);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
      }
    } catch (e: any) {
      setIsLoading(false);
      setEnteredQuoteValue('');
      setEnteredBaseValue('');
      setTotalValue(0);
      if (e.name === 'Rejected') {
        toast(<NewMessageNotification link={'none'} type={"error"} message={`User Rejected`}/>);
        return;
      }
    }
  };
  
  const handlePlaceLimitOrder = async () => {
    if (isLoadingFromStore) return;
    try {
      setIsLoading(true);
      const marketData = await handleValidationBeforePlacingOrder();
      if (marketData === 0) {
        setIsLoading(false);
        return;
      }
      const payload: any = await marketDataInstance.placeLimitOrder(
        typeOfRootOrder === 'BUY' ? BUY : SELL,
        enteredBaseValue,
        enteredQuoteValue
      );
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(payload);
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, payload)
        if (error) {
          setIsLoading(false);
          toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`}/>);
          return
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        setIsLoading(false);
        toast(<NewMessageNotification link={hash} type={"success"} message={`Transaction Executed successfully`}/>);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
        handleGetUserInfo()
        updateNeedToUpdateGlobalTrades(true);
        return;
      } else {
        setIsLoading(false);
        toast(<NewMessageNotification link={hash} type={"error"} message={`Transaction Failed`}/>);
        setEnteredQuoteValue('');
        setEnteredBaseValue('');
      }
    } catch (e: any) {
      setIsLoading(false);
      setEnteredQuoteValue('');
      setEnteredBaseValue('');
      setTotalValue('');
      if (e.name === 'Rejected') {
        toast(<NewMessageNotification link={"none"} type={"error"} message={`User Rejected`}/>);
        return;
      }
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Transaction Failed`}/>);
    }
  };

  const handleOpenWalletConnect = () => {
    handleWalletConnect();
  };

  const handleEnteredValueForBase = async (enteredValue: any) => {
    if (enteredValue?.length > 8) {
      return
    }
    const validatedValue = onTokenAmountChange(enteredValue);
    setEnteredBaseValue(validatedValue);
  };

  const handleEnteredValueForQuote = async (enteredValue: any) => {
    if (enteredValue?.length > 8) {
      return
    }
    const validatedValue = onTokenAmountChange(enteredValue);
    setEnteredQuoteValue(validatedValue);
  };

  useEffect(() => {
    setEnteredBaseValue('')
    setEnteredQuoteValue('')
    setTotalValue('')
  }, [typeOfOrder])

  useEffect(() => {
    if (typeOfOrder === 'limit') {
      if (enteredQuoteValue === '' || enteredBaseValue === '') {
        setTotalValue(0);
        return;
      }
      const value = parseFloat(enteredBaseValue) * parseFloat(enteredQuoteValue);
      setTotalValue(value)
    }

  }, [enteredQuoteValue, enteredBaseValue]);

  useEffect(() => {
    if (typeOfOrder === 'market') {
      if (enteredBaseValue === '') {
        setTotalValue(0);
        return;
      }
      const timeoutId = setTimeout(() => handleGetTotalValue(enteredBaseValue), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [enteredBaseValue])

  const handleGetTotalValue = async (value: any) => {
    try {
      setIsLoading(true);
      const tradeVolume = await marketDataInstance.getTradeVolume();
      if (isObjectNonEmpty(tradeVolume)) {
        setTotalValue(parseFloat(tradeVolume.price) * parseFloat(value));
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please try again after sometime`}/>);
    }
  };

  const handleOnClickRootTab = (type: string) => {
    setTypeOfRootOrder(type);
  };

  const handleTypeOfOrder = (type: string) => {
    setTypeOfOrder(type);
  };
  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])

  return (
    <div className="h-auto w-full  overflow-x-hidden  lg:px-[1rem] md:px-[1rem] sm:px-[1rem] xd:px-[1rem]  ">
      <div className="  z-[0] flex flex-col gap-4  shadow-[0rem_2.5rem_2rem_-3.313rem_rgba(0,0,0,0.06)]   border-none items-center justify-start   w-full flex">
        <div className=" flex flex-col gap-4 items-start justify-start     w-full sm:w-full ">
          <div className=" flex flex-row   h-14 md:h-auto items-center justify-center !bg-transparent   w-full">
            <div onClick={() => {
              handleOnClickRootTab('BUY');
            }}
              className={`${typeOfRootOrder === 'BUY' ? 'border-b-[2px] border-[white] text-[white] text-[16px] font-inter font-[700]' : 'font-[400] text-[#A5A5A6] text-[16px] font-inter'} buy_sell_tab`}
            >
              {t('buy')}
            </div>
            <div
              onClick={() => {
                handleOnClickRootTab('SELL');
              }}
              className={`${typeOfRootOrder === 'SELL' ? 'border-b-[2px] border-[white] text-[white] text-[16px] font-inter font-[700]' : 'font-[400] text-[#A5A5A6] text-[16px] font-inter'} buy_sell_tab`}
            >
              {t('sell')}
            </div>
          </div>
          <div className="flex flex-col gap-2 items-center !justify-between w-full h-full pr-[0.5rem]">
            <div className="w-full">
              <div className="flex flex-row h-8  items-center justify-between rounded-[1rem] !w-full">
                <div className="flex flex-row items-center justify-start  ">
                  <div className="text-left text-[#777879] text-[12px]  font-[400]">Order Type</div>
                </div>
                <div className="flex flex-row h-8 gap-2 items-center justify-start py-2 ">
                  <div
                    className={`${typeOfOrder === 'market' ? 'text-[#FFFFFF] font-extrabold' : ''
                      } cursor-pointer limit_market text-center text-sm w-auto px-1`}
                    onClick={() => {
                      handleTypeOfOrder('market');
                    }}
                  >
                    {t('market')}
                  </div>
                  <div
                    className={`${typeOfOrder === 'limit' ? 'text-[#FFFFFF] font-extrabold' : ''
                      } cursor-pointer limit_market text-center text-sm  w-auto px-1`}
                    onClick={() => {
                      handleTypeOfOrder('limit');
                    }}
                  >
                    {t('limit')}
                  </div>

                </div>

              </div>
              <div className="w-full">
                {typeOfOrder === 'limit' && (
                  <div className='w-full'>
                    <div className="bg-[#111213] border-[rgba(255,255,255,0.10)] border-[1px] rounded-[1rem]  my-[0.5rem] flex flex-col items-start justify-center py-[0.5rem] px-[1rem] rounded-[1rem] w-full sm:w-full">
                      {/* <div className="flex flex-col items-start justify-between w-full bg-transparent">
                      <div className="text-white-A700_99 text-xs w-auto bg-transparent">{t('limit_price')}</div>
                    </div> */}
                      <div className="flex flex-col items-center justify-start w-full bg-transparent">
                        <div className="flex flex-row gap-4 h-full items-center justify-start w-full bg-transparent">
                          {/* <div className="w-1/2 flex flex-row gap-2 items-center justify-start bg-transparent py-4">
                          <img className="h-6 md:h-auto rounded-[50%] w-6 bg-transparent" src={usdc} alt="usdc" />
                          <div className="text-base text-white-A700_cc w-auto bg-transparent">eUSDC</div>
                        </div> */}
                          <span className='text-[#4A4B4D] text-[12px] font-manrope font-[400] text-left'>Price</span>

                          <input
                            className=" text-2xl w-full bg-transparent  !text-[white] outline-none text-[1rem] font-[800] placeholder:text-white-A700_99   text-right  "
                            placeholder="0.00"
                            min="0"
                            value={enteredQuoteValue}
                            autoFocus={true}
                            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                            onPaste={preventPasteNegativeNumber}
                            onChange={(e) => {
                              handleEnteredValueForQuote(e.target.value);
                            }}
                          />
                          <span className='text-[#777879] text-[10px] font-manrope font-[500]'>eUSDC</span>
                        </div>
                      </div>
                    </div>
                    <div className=" text-white-A700_99 text-xs w-auto">Balance: {userinfo?.quoteBalance ? userinfo?.quoteBalance?.toFixed(3) : 0} eUSDC</div>
                  </div>
                )}
                <List className="flex flex-col gap-2 items-start w-full bg-transparent" orientation="vertical">
                  <div className='w-full'>
                    <div className="bg-[#111213] border-[rgba(255,255,255,0.10)] border-[1px] rounded-[1rem] my-[0.5rem] flex flex-col items-start justify-center py-[0.5rem] px-[1rem] rounded-[1rem] w-full sm:w-full">
                      <div className="flex flex-row gap-4 items-center bg-transparent justify-between w-full">
                        <span className='text-[#4A4B4D] text-[12px] font-manrope font-[400] text-left'>Amount</span>
                        <input
                          className=" text-2xl w-full bg-transparent  !text-[white] outline-none text-[1rem] font-[800] placeholder:text-white-A700_99   text-right  pr-2"
                          placeholder="0.00"
                          min="0"
                          autoFocus={true}
                          value={enteredBaseValue}
                          onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                          onChange={(e) => { handleEnteredValueForBase(e.target.value) }}
                          onPaste={preventPasteNegativeNumber}
                        />
                        <span className='text-[#777879] text-[10px] font-manrope font-[500] '>eAPT</span>
                      </div>
                    </div>
                    <div className=" text-white-A700_99 text-xs w-auto">Balance: {userinfo?.baseBalance ? userinfo?.baseBalance?.toFixed(3) : 0} eAPT</div>
                  </div>
                  <div className="bg-[#111213] border-[rgba(255,255,255,0.10)] border-[1px] rounded-[1rem]  flex flex-col gap-2 items-center justify-start   py-[0.5rem] px-[1rem]  w-full sm:w-full">
                    <div className="flex  flex-row  items-center justify-between bg-transparent w-full">
                      <span className='text-[#4A4B4D] text-[12px] font-manrope font-[400] text-left'>Total</span>

                      <input
                        className=" text-2xl w-full bg-transparent  !text-[white] outline-none text-[1rem] font-[800] placeholder:text-white-A700_99  mr-3 text-right pr-[8px] "
                        value={totalValue && totalValue?.toFixed(3)}
                        readOnly
                        placeholder='0.00'
                      />
                      <span className='text-[#777879] text-[10px] font-manrope font-[500]'>eUSDC</span>

                    </div>

                  </div>
                  {typeOfOrder === 'market' && <div className=" text-white-A700_99 text-xs w-auto">Balance: {userinfo?.quoteBalance ? userinfo?.quoteBalance?.toFixed(3) : 0} eUSDC</div>}
                </List>

                <Button
                  onClick={() => {
                    !connected && !isWalletConnected ? handleOpenWalletConnect() : typeOfOrder === "market" ? handlePlaceMarketOrder() : handlePlaceLimitOrder();
                  }}
                  className={`${isLoadingFromStore ? 'cursor-not-allowed ' : 'cursor-pointer'} mt-4 buy-button w-full`}
                >
                  <div className='buy-button-inner w-full h-full py-[0.938rem] flex flex-row justify-center items-center    font-bold leading-[normal] py-[0.938rem] rounded-[1rem] text-center text-gray-900 text-xl w-full'>
                    {isLoading ?  (
                      <Lottie
                        loop
                        animationData={Kanaloader}
                        play
                        className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                      />
                    ) :  <span className='button-text'>{!connected && !isWalletConnected ? 'Connect Wallet' : typeOfRootOrder === 'BUY' ? 'Buy' : 'Sell'}</span>
                  }
                  </div>
                </Button>
                <div className='flex flex-row justify-center items-center w-full text-[rgba(255,255,255,0.80)] text-[12px] font-inter font-[400] py-[0.5rem]'>{date}</div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySell;
