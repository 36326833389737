import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TradeDashboard from '../pages/TradeDashboard';
import { useTranslation } from "react-i18next";
import Kana from "../assets/icons/Logo.svg";
import Swap from '../assets/icons/swap.svg';
import Leaderboard from '../assets/icons/leader_board.svg';
import Community from '../assets/icons/community.svg';
import Learn from '../assets/icons/learn.svg';
import Referral from '../assets/icons/referral.svg';
import help from '../assets/icons/help.svg';
import ChevronDown from '../assets/icons/chevron-down.svg';
import OtherProducts from '../assets/icons/other_products.svg';
import TradeIcon from '../assets/icons/trade_icon.svg';
import StakeIcon from '../assets/icons/stack_icon.svg';
import KanaLogo from '../assets/icons/kana_logo.svg';
import { useStore } from '../store';
export const SidebarPage: React.FC = () => {
  const { t } = useTranslation();
  const [activeItemSidebar, setActiveItemSidebar] = useState(1);
  const [isSubMenu, setIsSubMenu] = useState(false);
   const [isChevronCorrect, setIsChevronCorrect] = useState(false);
   const {isLoading: isLoadingFromStore} = useStore()
  
  const handleClick = (id: any) => {
    if(isLoadingFromStore) return
    if (id === 1) {
      if(id === activeItemSidebar){
        return
      }else{
        setActiveItemSidebar(id);
      }
     
    }
    if (id === 2) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 3) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 4) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
    if (id === 5) {
      setIsChevronCorrect(false);
      setActiveItemSidebar(id);
      setIsSubMenu(false);
    }
  }

  const handleSubMenu = () => {
    if (isSubMenu === false) {
      setIsChevronCorrect(!isChevronCorrect);
      setIsSubMenu(true);
     } else {
      setIsChevronCorrect(!isChevronCorrect);
      setIsSubMenu(false);
    }
  }
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };
 
  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={` !z-[2]  !font-inter !fixed   h-full bg-[#17181A] flex flex-col gap-4 h-screen items-center justify-between  py-5  !top-0 !bottom-0 !left-0  xxl:flex xl:flex lg:hidden md:hidden sm:hidden xd:hidden w-auto ${isOpen ? '!w-[320px] ' : '!w-[95px]'}`}
      >
        <div className="w-auto hover:w-full bg-transparent flex flex-col text-left items-center h-full justify-between p-[1rem] w-full  ">
          <div className='flex flex-col text-left items-center h-full justify-start w-full'>
            <div className="p-[1rem]   w-full top-0 !sticky  mb-[36px] bg-[#17181A] !z-[2]" >
              {isOpen ? (
                <img className='bg-transparent !h-[32px] !w-auto' src={KanaLogo} alt="KanaLogo" />
              ) : (
                <img className='bg-transparent !h-[32px] !w-auto' src={Kana} alt="Kana" />
              )}
            </div>
            <div className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex flex-col justify-start items-start bg-transparent w-full gap-[8px]`}>
              <a href='/' rel="noreferrer" className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}  h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]`}>
                <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 1
                  ? "!font-[800] !opacity-[1] !bg-[rgba(255,255,255,0.06)]"
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(1)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={TradeIcon} alt="TradeIcon" />
                      <div>{t('trade')}</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent  !h-[1rem]' src={TradeIcon} alt="TradeIcon" />
                    </div>
                  )}

                </div>
              </a>
              <a rel="noreferrer" href=' https://app.kanalabs.io/' target='_blank' className='w-full flex  h-[52px] flex-row justify-start items-center    bg-transparent gap-[16px]'>
                <div className={` bg-transparent menu-item text-[16px] my-[4px] w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 2
                  ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(2)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={Swap} alt="Swap" />
                      <div>{t('swap')}</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>

                      <img className='bg-transparent  !h-[1rem]' src={Swap} alt="Swap" />
                    </div>
                  )}
                </div>
              </a>
              <a rel="noreferrer" href='https://app.kanalabs.io/stake-yield' target='_blank' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                  ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(3)}
                >
                  {isOpen ? (
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={StakeIcon} alt="Swap" />
                      <div>{t('stake')}</div>
                    </div>
                  ) : (
                    <div className=' flex flex-row justify-center items-center w-full'>
                      <img className='bg-transparent ' src={StakeIcon} alt="TradeIcon" />
                    </div>
                  )}
                </div>
              </a>
              {/* <a rel="noreferrer" href='https://app.kanalabs.io/leader-board' target='_blank' className='w-full flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                <div className={`bg-transparent menu-item text-[16px] my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[16px] px-[24px] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 4
                  ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(4)}
                >
                  <img className='bg-transparent' src={SwitchIcon} alt="SwitchIcon" />
                   {t('Switch')} 
                </div>
              </a> */}
              {/* <a rel="noreferrer" href=' https://kanalabs.io/contact-us-dev' target='_blank' className='w-full flex flex-row justify-start items-center    bg-transparent gap-[16px] w-full'>
                <div className={`bg-transparent menu-item text-[16px] my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[16px] px-[24px] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 5
                  ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                  : "opacity-[0.5]"
                  }`} onClick={() => handleClick(5)}
                >
                  <img className='bg-transparent' src={Referral} alt="Referral" />
                   {t('help')} 
                </div>
              </a> */}
              <div className={` menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-between items-center py-[16px] px-[20px] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[800]  ${activeItemSidebar === 1
                ? "font-[400] opacity-[0.5]  "
                : "font-[400] opacity-[0.5] "
                }`} onClick={() => handleClick(1)}
              >
                {isOpen ? (
                  <div className=' flex flex-row justify-between w-full items-center' onClick={handleSubMenu}>
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={OtherProducts} alt="OtherProducts" />
                      <div>Other Products</div>
                    </div>
                    <img src={ChevronDown} alt="ChevronDown"  className={isChevronCorrect ? ' rotate-180 ml-2' : ' ml-2 rotate-0'} />
                  </div>
                ) : (
                  <div className=' flex flex-row justify-center items-center w-full'>
                    <img className='bg-transparent ' src={OtherProducts} alt="OtherProducts" />
                  </div>
                )}
              </div>
              {isSubMenu ? <div className="submenu w-full">
                <a rel="noreferrer" href='https://app.kanalabs.io/leader-board' target='_blank' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                    ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`} onClick={() => handleClick(3)}
                  >
                    {isOpen ? (
                      <div className=' flex flex-row justify-start items-center gap-[16px]'>
                        <img className='bg-transparent  !h-[1rem]' src={Leaderboard} alt="Leaderboard" />
                        <div>Leaderboard</div>
                      </div>
                    ) : (
                      <div className=' flex flex-row justify-center items-center w-full'>
                        <img className='bg-transparent ' src={Leaderboard} alt="Leaderboard" />
                      </div>
                    )}
                  </div>
                </a>
                <a rel="noreferrer" href='https://app.kanalabs.io/referrals' target='_blank' className='w-full  h-[52px] flex flex-row justify-start items-center  bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px]  w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                    ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`} onClick={() => handleClick(3)}
                  >
                    {isOpen ? (
                      <div className=' flex flex-row justify-start items-center gap-[16px]'>
                        <img className='bg-transparent  !h-[1rem]' src={Referral} alt="Referral" />
                        <div>Referral</div>
                      </div>
                    ) : (
                      <div className=' flex flex-row justify-center items-center w-full'>
                        <img className='bg-transparent ' src={Referral} alt="Referral" />
                      </div>
                    )}
                  </div>
                </a>
              </div> : <></>}
            </div>
          </div>
          <a rel="noreferrer" href=' https://kanalabs.io/contact-us-dev' target='_blank' className='w-full'>
            {isOpen ? (
              <div className='bg-[#0C0C0D] py-[1rem] px-[1.4rem] rounded-[1rem]  w-full text-[white] text-[16px] font-[400] gap-[1rem] flex flex-row justify-start items-center'>
                <img className='bg-transparent !w-[1.5rem]  !h-[1.1rem]' src={help} alt="Swap" />
                <div>{t('help')}</div>
              </div>
            ) : (
              <div className='bg-[#0C0C0D] py-[1rem] px-[1.4rem] rounded-[1rem] w-full text-[white] text-[16px] font-[400] gap-[1rem] flex flex-row justify-center items-center'>
                <img className='bg-transparent  !w-[1.5rem] !h-[1.5rem]' src={help} alt="Swap" />
              </div>
            )}
          </a>
        </div>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TradeDashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};