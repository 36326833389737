import React, { useState } from 'react';
import { Button, List } from '../components';
import refresh from '../assets/icons/refresh.svg';
import popup_token from '../assets/icons/popup_token.svg';
import close from '../assets/icons/close.svg';
import small_popup_token from '../assets/icons/small_popup_token.svg';
import { useTranslation } from "react-i18next";
const AddCurrencyModel = () => {
  const { t } = useTranslation();
  const [isWithdrawClicked, setIsWithdrawClicked] = useState(false);
  const [isAddCurrencyClicked, setIsAddCurrencyClicked] = useState(false);
  const handleWithdraw = () => {
    setIsAddCurrencyClicked(!isAddCurrencyClicked);
    setIsWithdrawClicked(true);
  };
  return (
    <>
      {!isAddCurrencyClicked ? (
        <div className="!font-inter fixed inset-0 z-[24] xxl:pt-24 xl:pt-24 lg:pt-24 md:pt-24 sm:!pt-0 max-h-[full] flex flex-row justify-center items-center align-middle backdrop-blur-[16px] overflow-y-auto sm:w-full md:w-full">
          <div className="border-2 border-solid  xxl:border bxl:border-[#2e2e2e] xl:border-[#2e2e2e] sxl:border-[#2e2e2e] lg:border-[#2e2e2e] md:border-[#2e2e2e] sm:!border-none xd:!border-none rounded-[36px]">
            <div className="bg-black-900_66_01   flex flex-col gap-4 items-center justify-start p-8 md:px-5  sm:py-4 xd:py-4 rounded-[36px] min-w-[320px] sm:w-full !backdrop-blur-[40px]">
              <div className="flex flex-row sm:flex-row  gap-6 items-start justify-between w-full">
                <div
                  className="text-2xl md:text-[22px] text-white-A700 sm:text-xl w-auto"
                >
                  {t('add_currency')}
                </div>
                <div className="flex flex-row gap-2 items-center justify-start w-auto">
                  <Button
                    className="bg-teal-A400_19 cursor-pointer font-bold h-8 leading-[normal] min-w-[100px] py-[7px] rounded-lg text-center text-cyan-A200 text-sm"
                    onClick={handleWithdraw}
                  >
                    {t('withdraw')}
                  </Button>
                  <Button className="bg-white-A700_0a flex h-8 items-center justify-center p-1 rounded-lg w-8">
                    <img className="h-6 w-6" src={refresh} alt="refresh" />
                  </Button>
                  <img
                    className="h-5 w-5 cursor-pointer"
                    src={close}
                    alt="close"
                    onClick={() => setIsAddCurrencyClicked(!isAddCurrencyClicked)}
                  />
                </div>
              </div>
              <List className="flex flex-col gap-4 items-center w-full" orientation="vertical">
                <div className="flex sm:flex-1 flex-col items-start justify-start w-auto sm:w-full">
                  <div className="flex flex-row h-10 md:h-auto items-center justify-between w-[496px] sm:w-full">
                    <div
                      className="text-sm text-white-A700_7f w-auto"
                    >
                      {t('pay')}
                    </div>
                    <div className="flex flex-col items-start justify-start p-2 w-auto">
                      <div
                        className="bg-clip-text bg-gradient3  text-base text-transparent w-auto"
                      >
                        {t('connect_wallet')}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start w-[496px] sm:w-full">
                    <div className="bg-[#000000] flex flex-col gap-4 items-center justify-start sm:px-5 px-6 py-4 rounded-[24px] w-full">
                      <div className="flex sm:flex-col flex-row gap-4 h-[72px] md:h-auto items-center justify-start w-full">
                        <div className="bg-white-A700_19 flex flex-1 flex-col h-full items-start justify-start sm:px-5 px-6 py-2 rounded-[16px] w-full">
                          <div className="flex flex-row gap-2 py-2 items-center justify-start w-auto">
                            <div className="flex flex-col h-10 items-center justify-start w-10">
                              <div className="bg-teal-300 h-10 md:h-7  pt-1.5 relative rounded-[50%] w-10">
                                <img
                                  className="absolute h-7 inset-[0] justify-center m-auto object-cover w-[65%]"
                                  src={popup_token}
                                  alt="popup_token"
                                />
                                <img
                                  className="absolute bottom-[0] h-4 object-cover right-[0] w-4"
                                  src={small_popup_token}
                                  alt="small_popup_token"
                                />
                              </div>
                            </div>
                            <div className="flex flex-col items-start justify-center w-auto">
                              <div className="flex flex-col items-start justify-center w-auto">
                                <div
                                  className="text-white-A700 text-xl w-auto"
                                >
                                  USDT
                                </div>
                                <div
                                  className="text-[10px] text-white-A700 w-auto"
                                >
                                  <span className="text-white-A700 font-inter text-left font-normal">{t('on')}</span>
                                  <span className="text-white-A700 font-inter text-left font-medium"> </span>
                                  <span className="text-white-A700 font-inter text-left font-bold">BSC</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <input
                          className="text-right bg-transparent outline-none border-b border-solid border-white-A700_33 flex-1 h-full sm:pl-0 pl-[35px] py-4 md:text-3xl sm:text-[28px] text-[32px] placeholder:text-white-A700_99 text-[white] w-full sm:!text-center"
                          placeholder="0"
                        />
                      </div>
                      <div className="flex flex-row gap-2 h-[35px] md:h-auto items-center justify-between w-full">
                        <div className="flex flex-row gap-2 items-start justify-start w-auto">
                          <div
                            className="text-sm text-white-A700_66 w-auto"
                          >
                            {t('balance')}
                          </div>
                          <div
                            className="text-sm text-white-A700_cc w-auto"
                          >
                            -
                          </div>
                        </div>
                        <div className="flex flex-row gap-2 items-start justify-start w-auto">
                          <Button className="bg-white-A700_19 border border-black-900_19 border-solid cursor-pointer font-medium leading-[normal] min-w-[50px] py-2 rounded-lg text-center text-white-A700_cc text-xs tracking-[0.24px]">
                            {t('25%')}
                          </Button>
                          <Button className="bg-white-A700_19 border border-black-900_19 border-solid cursor-pointer font-medium leading-[normal] min-w-[50px] py-2 rounded-lg text-center text-white-A700_cc text-xs tracking-[0.24px]">
                            {t('50%')}
                          </Button>
                          <Button className="bg-white-A700_19 border border-black-900_19 border-solid cursor-pointer font-medium leading-[normal] min-w-[52px] py-2 rounded-lg text-center text-white-A700_cc text-xs tracking-[0.24px]">
                            {t('max')}
                          </Button>
                          <Button className="bg-gradient4  border border-black-900_19 border-solid cursor-pointer font-medium leading-[normal] min-w-[56px] py-2 rounded-lg text-center text-white-A700_cc text-xs tracking-[0.24px]">
                            {t('clear')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-1 flex-col items-start justify-start w-auto sm:w-full">
                  <div className="flex flex-row h-10 md:h-auto items-center justify-between w-[496px] sm:w-full">
                    <div
                      className="text-sm text-white-A700_7f w-auto"
                    >
                      {t('receive')}
                    </div>
                    <div className="flex flex-col items-start justify-start p-2 w-auto">
                      <div
                        className="bg-clip-text bg-gradient3  text-base text-transparent w-auto"
                      >{t('deposit_account')}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start w-[496px] sm:w-full">
                    <div className="bg-[#000000] flex flex-col gap-4 items-center justify-start sm:px-5 px-6 py-4 rounded-[24px] w-full">
                      <div className="flex sm:flex-col flex-row gap-4 h-[72px] md:h-auto items-center justify-start w-full">
                        <div className="bg-white-A700_19 flex flex-1 flex-col h-full items-start justify-start sm:px-5 px-6 py-2 rounded-[16px] w-full">
                          <div className="flex flex-row gap-2 py-2 items-center justify-start w-auto">
                            <div className="flex flex-col h-10 items-center justify-start w-10">
                              <div className="bg-teal-300 h-10 md:h-7  pt-1.5 relative rounded-[50%] w-10">
                                <img
                                  className="absolute h-7 inset-[0] justify-center m-auto object-cover w-[65%]"
                                  src={popup_token}
                                  alt="popup_token"
                                />
                                <img
                                  className="absolute bottom-[0] h-4 object-cover right-[0] w-4"
                                  src={small_popup_token}
                                  alt="small_popup_token"
                                />
                              </div>
                            </div>
                            <div className="flex flex-col items-start justify-center w-auto">
                              <div className="flex flex-col items-start justify-center w-auto">
                                <div
                                  className="text-white-A700 text-xl w-auto"
                                >
                                  USDT
                                </div>
                                <div
                                  className="text-[10px] text-white-A700 w-auto"
                                >
                                  <span className="text-white-A700 font-inter text-left font-normal">{t('on')}</span>
                                  <span className="text-white-A700 font-inter text-left font-medium"> </span>
                                  <span className="text-white-A700 font-inter text-left font-bold">BSC</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <input
                          className="text-right bg-transparent outline-none border-b border-solid border-white-A700_33 flex-1 h-full sm:pl-0 pl-[35px] py-4 md:text-3xl sm:text-[28px] text-[32px] placeholder:text-white-A700_99 text-[white] w-full sm:!text-center"
                          placeholder="0"
                        />
                      </div>
                      <div className="flex flex-row gap-2 h-[35px] md:h-auto items-center justify-between w-full">
                        <div className="flex flex-row gap-2 items-start justify-start w-auto">
                          <div
                            className="text-sm text-white-A700_66 w-auto"
                          >
                            {t('balance')}
                          </div>
                          <div
                            className="text-sm text-white-A700_cc w-auto"
                          >
                            -
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start w-auto">
                          <Button className="bg-gradient4  border border-black-900_19 border-solid cursor-pointer font-medium leading-[normal] min-w-[56px] py-2 rounded-lg text-center text-white-A700_cc text-xs tracking-[0.24px]">
                            {t('clear')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </List>
              <Button className="bg-gradient1  border border-black-900_19 border-solid cursor-pointer font-bold leading-[normal] py-[15px] rounded-[28px] text-center text-gray-900 text-xl w-full">
                {t('connect_wallet')}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

    </>
  );
};

export default AddCurrencyModel;
