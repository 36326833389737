import React, {useMemo, useState } from 'react';
import closeIcon from '../assets/icons/close.svg';
import { useAptosContext } from '../providers/AptosWalletContext';
import { Wallet, WalletReadyState } from '@manahippo/aptos-wallet-adapter';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from "react-i18next";
import { useStore } from "../store";
import ConnectSocialLogin from './ConnectSocialLogin';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
interface ConnectWalletInterface {
  handleClose(typeOfLogin: string): void;

}
const ConnectWallet = (props: ConnectWalletInterface) => {

  const [selectedTab, setSelectedTab] = useState(0);

  // Function to handle tab selection
  const handleTabSelect = (index: any) => {
    setSelectedTab(index);
  };
  const tabClasses = `flex xxl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] xd:w-[21rem] h-[3.5rem] cursor-pointer transition-all ease-in-out duration-500  bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-b-2 border-b-[#313337]
}`;
  const panelClasses = `xxl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] xd:w-[21rem] xxl:h-[19rem] bxl:h-[19rem] xl:h-[19rem] sxl:h-[19rem] lg:h-[19rem] md:h-[19rem] sm:h-[19rem] xd:h-[19rem] bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] rounded-b-[1rem] 
} `;
  const { t } = useTranslation();
  const { handleClose } = props;
  const { wallets, connect, account, disconnect }: any = useAptosContext();
  const { updateBalanceUpdate, isLoading: isLoadingFromStore, updateIsLoading,  updateNeedToUpdateGlobalTrades } = useStore()
  const [detected, undetected] = useMemo(() => {
    const detected: any[] = [];
    const undetected: any[] = [];
    for (const wallet of wallets) {
      if (wallet.readyState === WalletReadyState.Installed || wallet.readyState === WalletReadyState.Loadable) {
        detected.push(wallet);
      } else if (wallet.readyState === WalletReadyState.NotDetected) {
        undetected.push(wallet);
      }
    }
    return [detected, undetected];
  }, [wallets]);
  const [isConected, setIsConnected] = useState<any>();

  const handleConnectWallet = async (wallet: Wallet) => {
    updateIsLoading(true)
    try {
      await connect(wallet.adapter.name)
      setIsConnected(wallet)
      updateNeedToUpdateGlobalTrades(true)
    } catch (e) {
      console.log()
    } finally {
      updateBalanceUpdate(true)
      handleClose('extension')
      updateIsLoading(false)
    }
  }

  const handleConnectSocialLogin = () => {
    handleClose('social')
  }

  return (
    <div className="fixed z-[2] inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-lg flex flex-row justify-center  items-center  ">
      {isLoadingFromStore ? <>  <Lottie
                        loop
                        animationData={Kanaloader}
                        play
                        className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                      /></> :  
      <div
        className={`!bg-[#17181A] xxl:w-[35rem] bxl:w-[35rem] xl:w-[35rem] sxl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] xd:w-[21rem] 
           border-[#1e1f22] 
           border-2 rounded-[1rem]`}
      >
        <div className="flex justify-between p-[3%_5%]  !bg-[rgba(255,255,255,0.06)] rounded-t-[1rem]   ">
          <div className='text-[white] font-[700] text-[1.25rem]'>
            {t('connect_wallet')}
          </div>
          <img
            src={closeIcon}
            alt="close"
            className="cursor-pointer"
            onClick={() => handleClose('close')}
          />
        </div>
        <div className="flex justify-center   ">
          <Tabs>
            <TabList className={tabClasses}>
              <Tab className={`text-[#A5A5A6] pt-3 outline-none Tab_container px-8  ${selectedTab === 0 ? 'selected-tab' : ''}`} onClick={() => handleTabSelect(0)}>
                Social Wallets
              </Tab>
              <Tab className={`text-[#A5A5A6] pt-3 outline-none Tab_container px-8  ${selectedTab === 1 ? 'selected-tab' : ''}`} onClick={() => handleTabSelect(1)}>
                Web3 Wallets
              </Tab>
            </TabList>
            <TabPanel>
              <div className={panelClasses}>
                <ConnectSocialLogin handleClose={handleConnectSocialLogin} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className={panelClasses}>
                <ul className="flex flex-col overflow-y-auto h-full">
                  {detected.length > 0 ? (
                    detected?.map((item: any, index: any) => (
                      <div
                        className={`flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[24px]   cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] w-full`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
                        onClick={() => handleConnectWallet(item)}
                        key={index}
                      >
                        <img className="w-14 pl-8 bg-transparent" src={item.adapter.icon} alt="token" />
                        <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
                          <div className="text-lg text-[white]">{item.adapter.name}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center bg-transparent text-[white]  text-lg font-bold flex flex-row justify-center items-center w-full h-full">{t('no_wallets_found')}</div>
                  )}
                </ul>

              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
}
    </div>
  );
};

export default ConnectWallet;

