import { ProSidebarProvider } from 'react-pro-sidebar';
import React, { useCallback, useEffect } from 'react';
import {SidebarPage} from './components/Sidebar';
import AptosWalletProvider, { useAptosContext } from './providers/AptosWalletContext';
import { useStore } from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { econia } from './utils/Constants';
import { ENVIRONMENT, eventId, subscribeEvents } from '@kanalabs/trade';
import { WebAuthInit } from './Web3Auth';
import { handleInitWallet } from './utils/helper';
import './index.css'
const App = () => {
  
  const {address,updateSdk, updateAddress, updateIsWalletConnected, updateAvailableMarkets, updateRegisteredMarkets, updateMarketDataInstance, updateOrderBook, updateGlobalTrades, isLoading, updateAptosWeb3Instance } = useStore();
  const {connected} = useAptosContext();
  useEffect(() => {
    getMarketDetails();
  }, []);

  const socialLoginInit = useCallback(async () => {
    const { error, web3Instance } = await WebAuthInit();
    if (error) return;
    updateAptosWeb3Instance(web3Instance);
    if(web3Instance?.status === "connected"){
     const {error, wallet, address} = await  handleInitWallet(web3Instance)
     if(error) return;
     updateSdk(wallet)
     updateAddress(address)
    }
  }, []);
  
  useEffect(() => {
    socialLoginInit();
  }, [socialLoginInit]);

  const getMarketDetails = async () => {
    const registeredMarkets = await econia.fetchRegisteredMarkets();
    updateRegisteredMarkets(registeredMarkets);
    const availableMarkets = await econia.getAvailableMarkets(registeredMarkets);
    updateAvailableMarkets(availableMarkets);
    const marketDataInstance = await econia.markets(
      registeredMarkets.filter((market: any) => market.marketId === 3)[0],
      ENVIRONMENT.TESTNET
    );
    updateMarketDataInstance(marketDataInstance)
    marketDataInstance.subscribeToTradeHistory(false, {
      offset: 10,
      limit: 50,
      order: 'desc',
    });
    marketDataInstance.subscribeToOrderBook();
    subscribeEvents.on(eventId.orderBook, (data) => {
      updateOrderBook(data)
    });
    subscribeEvents.on(eventId.tradeHistory, (data) => {
      updateGlobalTrades(data)
    });
  };

  useEffect(()=>{
    if(address !== ""){
      updateIsWalletConnected(true)
    }else{
      updateIsWalletConnected(false)
    }
  },[address])

  return (
    <AptosWalletProvider>
      {/* <ProSidebarProvider> */}
        <div className="relative w-full">
          <SidebarPage />
        </div>
        <ToastContainer position="bottom-right"   theme='dark' icon={false} />
      {/* </ProSidebarProvider> */}
    </AptosWalletProvider>
  );
};

export default App;
