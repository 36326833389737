import React, { useCallback, useEffect, useState } from "react";
import Facebook from "../assets/icons/facebook__waitlist.svg";
import Twitter from "../assets/icons/twitter__waitlist.svg";
import Mail from "../assets/icons/mail_waitlist.svg";
import Google from "../assets/icons/google__waitlist.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "../store";
import { WALLET_ADAPTERS, WALLET_ADAPTER_TYPE } from "@web3auth/base";
import { handleAddToWaitlist, handleInitWallet } from "../utils/helper";
import { toast } from "react-toastify";
import NewMessageNotification from "../components/NewMessageNotification";

type LOGIN_PROVIDER_TYPE =
  | "google"
  | "facebook"
  | "apple"
  | "discord"
  | "twitch"
  | "github"
  | "twitter"
  | "email_passwordless"
  | "email";

interface ConnectSocialLogininterface {
  handleClose(): void;
}
const ConnectSocialLogin = (props: ConnectSocialLogininterface) => {
  const { handleClose } = props
  const {
    aptosWeb3Instance,
    updateAddress,
    updateSdk,
    updateIsLoading,
    isLoading: isLoadingFromStore
  } = useStore();
  const { t } = useTranslation();

  const loginWithAdapter = useCallback(
    async (
      adapter: WALLET_ADAPTER_TYPE,
      loginProvider?: LOGIN_PROVIDER_TYPE,
      login_hint?: string
    ) => {
      toast.dismiss()
      if (!aptosWeb3Instance) {
        updateIsLoading(false);
        localStorage.clear();
        toast(<NewMessageNotification link={"none"} type={"error"} message={`something went wrong please try again`} />);
        return false;
      }
      let web3Provider;
      try {
        web3Provider = await aptosWeb3Instance.connectTo(adapter, {
          loginProvider,
          login_hint,
        });
        if (!web3Provider) {
          updateIsLoading(false);
          localStorage.clear();
          toast(<NewMessageNotification link={"none"} type={"error"} message={`Failed to get connection Provider`} />);
          return false;
        }
        return true;
      } catch (e) {
        updateIsLoading(false);
        localStorage.clear();
        toast(<NewMessageNotification link={"none"} type={"error"} message={`Failed to login! Reason: ${e instanceof Error && e?.message ? e.message : "unknown"
          }.`} />);
        return false;
      }
    },
    [aptosWeb3Instance]
  );

  const handleCreateWallet = async () => {
    const { error, wallet, address: addressFromAptosClient } = await handleInitWallet(aptosWeb3Instance);
    if (error) {
      handleClose()
      return;
    }
    updateAddress(addressFromAptosClient);
    updateSdk(wallet)
    await handleAddToWaitlist(addressFromAptosClient);
    handleClose();
  }

  const handleConnectWallet = async (typeOfLogin: LOGIN_PROVIDER_TYPE) => {
    if (isLoadingFromStore) return
    if (aptosWeb3Instance?.status === "connected") {
      aptosWeb3Instance.logout();
    }
    updateIsLoading(true);
    const response = await loginWithOpenLogin(typeOfLogin);
    if (response) {
      updateIsLoading(false);
      await handleCreateWallet()
    }
  };

  const loginWithOpenLogin = useCallback(
    async (loginProvider: LOGIN_PROVIDER_TYPE, login_hint?: string) =>
      loginWithAdapter(WALLET_ADAPTERS.OPENLOGIN, loginProvider, login_hint),
    [loginWithAdapter]
  );

  return (
    <>
      <div className="flex flex-col">
        <div
          onClick={() => handleConnectWallet('google')}
          className={` ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[1.5rem] cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] w-full`}
        >
          <img className="w-14 pl-8 bg-transparent" src={Google} alt="token" />
          <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
            <div className="text-lg text-[white]">Google</div>
          </div>
        </div>
        <div
          onClick={() => handleConnectWallet('facebook')}
          className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[1.5rem]  cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] w-full`}
        >
          <img className="w-14 pl-8 bg-transparent" src={Facebook} alt="token" />
          <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
            <div className="text-lg text-[white]">Facebook</div>
          </div>
        </div>
        <div
          onClick={() => handleConnectWallet('twitter')}
          className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[1.5rem]    cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] w-full`}
        >
          <img className="w-14 pl-8 bg-transparent" src={Twitter} alt="token" />
          <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
            <div className="text-lg text-[white]">Twitter</div>
          </div>
        </div>
        <div
          onClick={() => handleConnectWallet('email_passwordless')}
          className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex bg-transparent flex-row justify-start gap-[1rem] items-center p-[1.5rem]  cursor-pointer hover:!bg-[rgba(0,249,169,0.1)] hover:rounded-[1rem] w-full`}
        >
          <img className="w-14 pl-8 bg-transparent" src={Mail} alt="token" />
          <div className="font-inter pl-[0.5rem] bg-transparent font-bold leading-5  ">
            <div className="text-lg text-[white]">Email</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectSocialLogin;


