import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { ADAPTER_EVENTS } from '@web3auth/base';
import { CommonPrivateKeyProvider } from "@web3auth/base-provider";

export const WebAuthInit = async () => {
  try {
    const clientId: any = "BHu-fep3dHRLAMMFZNSd7Yudq8imJ_lqtmfRtqUvBJnsk1vj1VSBe5fZFmvmw_YJonvYydTO30mxhC4GWBPnDIk";
    const chainConfig = {
        chainNamespace: CHAIN_NAMESPACES.OTHER,
        chainId: "0x1",
        rpcTarget: "https://fullnode.testnet.aptoslabs.com/v1",
        displayName: "Aptos Testnet",
        blockExplorer: "",
        ticker: "APT",
        tickerName: "Aptos",
    };
    const web3AuthInstance = new Web3AuthNoModal({
      clientId,
      chainConfig,
      web3AuthNetwork: "mainnet",
    });

    const privateKeyProvider = new CommonPrivateKeyProvider({
        config: { chainConfig },
      });

    const openloginAdapter: any = new OpenloginAdapter({
      loginSettings: {
        mfaLevel: "none",
      },
      privateKeyProvider,
    });
    web3AuthInstance.configureAdapter(openloginAdapter);
    web3AuthInstance.on(ADAPTER_EVENTS.CONNECTED, () => {
      if (!web3AuthInstance?.provider)
        return { error: true, web3Instance: null };
    });

    web3AuthInstance.on(ADAPTER_EVENTS.ERRORED, () => {
      // setIsSigningIn(false);
    });
    await web3AuthInstance.init();
    return { error: false, web3Instance: web3AuthInstance };
  } catch (e) {
    return { error: true, web3Instance: null };
  }
};
