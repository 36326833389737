import React, { useState } from 'react';
import { Button } from '../components';
import WithdrawModel from './WithdrawModel';
import AddCurrencyModel from './AddCurrencyModel';
import PowerButton from '../assets/icons/powerbutton.svg';
import Copy from '../assets/icons/copy.svg';
import kana from '../assets/icons/kanalabs-icon.svg';
import DropdownMenu from '../pages/DropdownMenu';
import { useTranslation } from "react-i18next";
import FaucetDropdownMenu from './FaucetDropdownMenu';
import Deposit from './Deposit';
import { useAptosContext } from '../providers/AptosWalletContext';
import ConnectWallet from './ConnectWallet';
import { useStore } from '../store';
import { toast } from 'react-toastify';
import EconiaImg from '../assets/icons/econia_img.svg';
import SwitchVertical from '../assets/icons/switch-vertical.svg';
import UserCircle from '../assets/icons/user-circle.svg';
import TradeIcon from '../assets/icons/trade_icon.svg';
import StakeIcon from '../assets/icons/stack_icon.svg';
import Swap from '../assets/icons/swap.svg';
import Referral from '../assets/icons/referral.svg';
import Leaderboard from '../assets/icons/leader_board.svg';
import { CopyToClipboard } from "react-copy-to-clipboard";
import NewMessageNotification from '../components/NewMessageNotification';
const Header: React.FC = () => {
  const { t } = useTranslation();
  const { updateNeedToUpdateGlobalTrades, address: socialAddress, sdk, aptosWeb3Instance, updateAddress, updateSdk, isWalletConnected, isLoading: isLoadingFromStore } = useStore()
  const { connected, disconnect, wallet, account }: any = useAptosContext()
  const [isWithdrawClicked, setIsWithdrawClicked] = useState(false);
  const [isAddCurrencyClicked, setIsAddCurrencyClicked] = useState(false);
  const [isDepositClicked, setIsDepositClicked] = useState(false);
  const [isConnectWalletisClicked, setIsConnectWalletClicked] = useState<boolean>(false);
  const handleWithdraw = () => {
    setIsAddCurrencyClicked(false);
    setIsWithdrawClicked(true);
  };

  const handleAddCurrency = () => {
    setIsWithdrawClicked(false);
    setIsAddCurrencyClicked(true);
  };

  const [activeItemSidebar, setActiveItemSidebar] = useState(1);
  const handleDeposit = () => {
    toast.dismiss()
    if (isLoadingFromStore) return
    if (connected) {
      if (wallet && wallet?.adapter?.network?.name !== 'Testnet') {
        toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Switch Network From Mainnet To Testnet in ${wallet.adapter.name} extension and try again!`} />);
        disconnect()
        return
      }
      setIsWithdrawClicked(false);
      setIsDepositClicked(true);
    } else if (aptosWeb3Instance.status === 'connected') {
      setIsWithdrawClicked(false);
      setIsDepositClicked(true);
    } else {
      toast(<NewMessageNotification link={"none"} type={"error"} message={`Please Connect Wallet`} />);
    }
  }
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  const handleDisconnect = () => {
    if (isLoadingFromStore) return
    if (connected) {
      disconnect()
      return
    } else if (aptosWeb3Instance.status === 'connected') {
      aptosWeb3Instance.logout();
      updateAddress("")
      updateSdk({})
    }
  }

  const handleConnect = () => {
    setIsConnectWalletClicked(true);
  }

  const handleCloseWalletConnect = () => {
    updateNeedToUpdateGlobalTrades(true)
    setIsConnectWalletClicked(false);
  };

  const handleCloseModal = (value: boolean) => {
    if (value) {
      setIsWithdrawClicked(false);
      setIsDepositClicked(false);
    }
  }

  const handleWithDrawModal = (value: boolean) => {
    if (value) {
      setIsDepositClicked(false);
      setIsWithdrawClicked(true);
    }
  }

  const handleDepositModal = (value: boolean) => {
    if (value) {
      setIsWithdrawClicked(false);
      setIsDepositClicked(true);
    }
  }

  const onCopy = () => {
    toast.dismiss()
    toast(<NewMessageNotification link={"none"} type={"success"} message={`Address Copied!`} />);
  };
  return (
    <>
      <div className="h-auto z-[1] bg-[#111213] !fixed !top-0 flex flex-col justify-between items-start !font-inter w-full xxl:ml-4 bxl:ml-4 xl:ml-4 sxl:ml-4 lg:ml-4 md:ml-0 sm:ml-0 xd:ml-0 ">
        <div className="!h-24 flex flex-row  items-center justify-between  w-full xxl:!px-4 bxl:!px-4 xl:!px-4 sxl:!px-4 lg:!px-0 md:!px-0 w-full ">

          <div className=" xxl:ml-16 bxl:ml-16 xl:ml-16 sxl:ml-16 lg:ml-0 md:ml-0 sm:ml-0 xd:ml-0 !gap-[1rem]  p-[1rem]  flex flex-row    !items-center !justify-end top-[0] w-full      ">
            <div className='  xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden !w-1/4'>
              <DropdownMenu />
            </div>
            <div className='   xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden !w-1/4'>
              <FaucetDropdownMenu />
            </div>

            <Button
              className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} !w-1/4   xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-start items-center gap-[8px] !bg-[#1D1E20]   font-bold h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[2.5rem]  text-[#2ED3B7] font-inter font-[400] text-[1rem]`}
              onClick={() => handleDeposit()}
            >
              <img src={SwitchVertical} alt='SwitchVertical' className='!bg-transparent' />
              Deposit / Withdraw
            </Button>
            <Button
              onClick={() => isWalletConnected || connected ? '' : handleConnect()}
              className="common-pointer  !w-1/4   xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-row justify-start items-center gap-[8px] !bg-[#1D1E20]   cursor-pointer font-bold h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[2.5rem]  text-[#2ED3B7] font-inter font-[400] text-[1rem]"
            >
              {isWalletConnected || connected ?
                <div className='flex flex-row justify-start items-center gap-[10px]'>
                  {/* <img src={connected ? 'https://raw.githubusercontent.com/hippospace/aptos-wallet-adapter/main/logos/martian.png': Copy} alt='wallet' />
                  */}
                  {connected ? account?.address?.slice(0, 7) + '..' + account?.address?.toString()?.slice(-7) : socialAddress?.slice(0, 7) + '..' + socialAddress?.toString()?.slice(-7)}
                  <CopyToClipboard
                    text={connected ? account.address : socialAddress}
                    onCopy={() => onCopy()}
                  >
                    <img src={Copy} alt='copy' />
                  </CopyToClipboard>
                  <img src={PowerButton} alt='PowerButton' className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => isWalletConnected || connected ? handleDisconnect() : ''} />
                </div>
                : <div className='flex flex-row justify-start items-center gap-[20px]'>
                  <img src={UserCircle} alt='UserCircle' className='!bg-transparent' />
                  Connect Wallet
                </div>
              }
            </Button>
            <div className=' !w-[100px] xxl:flex bxl:flex xl:flex sxl:flex lg:hidden md:hidden sm:hidden xd:hidden flex-col justify-start items-start gap-[8px]'>
              <div className='text-[rgba(255,255,255,0.50)] text-[12px] font-[400] font-inter'>Powered by</div>
              <div>
                <img src={EconiaImg} alt='EconiaImg' />
              </div>
            </div>
            <div className="lg:pr-3 md:pr-2 sm:pr-2 xd:pr-3  !w-full justify-between items-center xxl:!hidden max-xxl:!hidden bxl:!hidden xl:!hidden sxl:!hidden lg:!flex md:!flex sm:!flex xd:!flex lg:!flex-row">
              <div className='w-[4.375rem] h-[4.375rem]'>
                <img src={kana} alt="kana-logo" />
              </div>
              <div
                className="text-[2rem] !float-right text-[rgba(255,255,255,0.6)] cursor-pointer xxl:!hidden xl:hidden lg:flex md:flex sm:flex"
                onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
              >
                <i className={isMobileMenuActive ? 'fa fa-user' : 'fa fa-user'}></i>
              </div>
            </div>
          </div>
        </div>
        {isMobileMenuActive ? (
          <ul
            className={`flex-1 justify-self-center py-3 mt-24 xxl:hidden bxl:hidden xl:hidden sxl:hidden lg:flex md:flex md:pb-0 md:mt-24 sm:mt-24 xd:mt-24 z-[9] ${isMobileMenuActive ? '!fixed !inset-0 !backdrop-blur-lg	!bg-transparent' : 'hidden'
              }`}
          >
            <div className="flex flex-col  items-start justify-start align-middle  h-full  md:w-full lg:!px-16 md:!px-16 sm:!px-8 xd:!px-8 !bg-transparent">
              <div className='flex flex-col justify-start items-start bg-transparent w-full gap-[8px]'>
                <a href='/' rel="noreferrer" className=' h-[52px] flex flex-row justify-start items-center  w-full  bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 1
                    ? "!font-[800] !opacity-[1] !bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={TradeIcon} alt="TradeIcon" />
                      <div>{t('trade')}</div>
                    </div>


                  </div>
                </a>
                <a rel="noreferrer" href=' https://app.kanalabs.io/' target='_blank' className='w-full flex  h-[52px] flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 2
                    ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={Swap} alt="Swap" />
                      <div>{t('swap')}</div>
                    </div>

                  </div>
                </a>
                <a rel="noreferrer" href='https://app.kanalabs.io/stake-yield' target='_blank' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                    ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={StakeIcon} alt="Swap" />
                      <div>{t('stake')}</div>
                    </div>

                  </div>
                </a>
                <a rel="noreferrer" href='https://app.kanalabs.io/leader-board' target='_blank' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                    ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={Leaderboard} alt="Leaderboard" />
                      <div>{t('leaderboard')}</div>
                    </div>

                  </div>
                </a>
                <a rel="noreferrer" href='https://app.kanalabs.io/referrals' target='_blank' className='w-full  h-[52px] flex flex-row justify-start items-center    bg-transparent gap-[16px]'>
                  <div className={` bg-transparent menu-item text-[16px] my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[1rem] px-[1.4rem] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${activeItemSidebar === 3
                    ? "font-[800] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                    : "opacity-[0.5]"
                    }`}
                  >
                    <div className=' flex flex-row justify-start items-center gap-[16px]'>
                      <img className='bg-transparent  !h-[1rem]' src={Referral} alt="Referral" />
                      <div>{t('Referral')}</div>
                    </div>

                  </div>
                </a>
              </div>
              <div className='w-full my-1'>
                <DropdownMenu />
              </div>
              <div className='w-full my-1'>
                <FaucetDropdownMenu />
              </div>
              <div
                className={`${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}  my-2 flex w-[100%] flex-row justify-start items-center gap-[8px] !bg-[#1D1E20]  font-bold h-[3.875rem] leading-[normal]   py-[1rem]     font-inter font-[400] text-[1rem]  gap-[8px]     font-bold h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[2.5rem]  text-[#2ED3B7] font-inter font-[400] text-[1rem]  `}
                onClick={() => handleDeposit()}
              >
                <img src={SwitchVertical} alt='SwitchVertical' className='!bg-transparent' />
                Deposit / Withdraw
              </div>
              <div onClick={() => isWalletConnected || connected ? '' : handleConnect()}
                className=" my-2 flex w-[100%] flex-row justify-start items-center gap-[8px] !bg-[#1D1E20]   cursor-pointer font-bold h-[3.875rem] leading-[normal]   py-[1rem]     font-inter font-[400] text-[1rem]  gap-[8px]     cursor-pointer font-bold h-[3.875rem] leading-[normal]   py-[1rem] px-[1.5rem]  rounded-[2.5rem]  text-[#2ED3B7] font-inter font-[400] text-[1rem]  ">
                {isWalletConnected || connected ?
                  <div className='flex flex-row justify-start items-center gap-[20px]'>
                    {connected ? account?.address?.slice(0, 7) + '..' + account?.address?.toString()?.slice(-7) : socialAddress?.slice(0, 7) + '..' + socialAddress?.toString()?.slice(-7)}
                    <img src={Copy} alt='copy' />
                    <img src={PowerButton} alt='PowerButton' onClick={() => isWalletConnected || connected ? handleDisconnect() : ''} />
                  </div>
                  : <div className='flex flex-row justify-start items-center gap-[20px]'>
                    <img src={UserCircle} alt='UserCircle' className='!bg-transparent' />
                    Connect Wallet
                  </div>
                }
              </div>
            </div>
          </ul>
        ) : (
          <ul></ul>
        )}
      </div>
      {isConnectWalletisClicked && <ConnectWallet handleClose={handleCloseWalletConnect} />}
      {isWithdrawClicked && <WithdrawModel handleCloseModal={handleCloseModal} handleDepositModal={handleDepositModal} />}
      {isAddCurrencyClicked && <AddCurrencyModel />}
      {isDepositClicked && <Deposit handleCloseModal={handleCloseModal} handleWithDrawModal={handleWithDrawModal} />}
    </>
  );
};

export default Header;