import React, { useEffect, useMemo, useState } from 'react';
import { useAptosContext } from '../providers/AptosWalletContext';
import { WalletReadyState } from '@manahippo/aptos-wallet-adapter';
import ConnectWallet from './ConnectWallet';
import TradingViewWidget from '../components/TradingView';
import { TradeTable } from '@kanalabs/trade';
import BuySell from './BuySell';
import OrderBook from './OrderBook';
import AllTrades from './AllTrades';
import Loader from '../components/Loader';
import Header from './Header';
import { useStore } from '../store';
import { isObjectNonEmpty } from '../utils/helper';


const TradeFivePage = () => {
  const { wallets, account } = useAptosContext();
  const { marketDataInstance, needToUpdateGlobalTrades, updateIsLoading, updateNeedToUpdateGlobalTrades } = useStore()
  const [isConnectWalletisClicked, setIsConnectWalletClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [globalTrades, setGlobalTrades] = useState<TradeTable[]>();
  const [socialLoginClicked, setIsSocialLoginClicked] = useState<boolean>(false)
  const [graphData, setGraphData] = useState<any[]>();
  const [detected, undetected] = useMemo(() => {
    const detected: any[] = [];
    const undetected: any[] = [];
    for (const wallet of wallets) {
      if (wallet.readyState === WalletReadyState.Installed || wallet.readyState === WalletReadyState.Loadable) {
        detected.push(wallet);
      } else if (wallet.readyState === WalletReadyState.NotDetected) {
        undetected.push(wallet);
      }
    }
    return [detected, undetected];
  }, [wallets]);

  const handleGetGlobalTrades = async () => {
    if (isObjectNonEmpty(marketDataInstance)) {
      try {
        const getAllTrades = await marketDataInstance?.getAllTrades(false) as TradeTable[];
        const uniqueSet = new Set();
        const result: TradeTable[] = [];
        getAllTrades.forEach((obj: any) => {
          const propertyValue = obj["txn_version"];
          if (!uniqueSet.has(propertyValue)) {
            uniqueSet.add(propertyValue);
            result.push(obj);
          }
        });
        const sortedAsks = [...result].sort((a, b) => b.txn_version - a.txn_version);
        setGlobalTrades(sortedAsks)
        setGraphData(sortedAsks)

      } catch (e) {
        console.log()
      }
    }
  }

  useEffect(() => {
    if (needToUpdateGlobalTrades) {
      handleGetGlobalTrades();
    }
  }, [marketDataInstance, account, needToUpdateGlobalTrades])

  const handleWalletConnect = () => {
    setIsConnectWalletClicked(true);
  };

  const handleCloseWalletConnect = (typeOfLogin: string) => {
    
    updateNeedToUpdateGlobalTrades(true)
    setIsConnectWalletClicked(false);

  };

  const handleSocialLoginModelClose = () => {
    setIsSocialLoginClicked(false)
  }
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => { setIsLoading(false) }, 6000)
    }
  }, [isLoading])

  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])


  return (
    <>
      {isLoading ? <Loader /> : <>
        <Header />
        <div className=' xxl:w-[92%] mt-24 xxl:ml-24 bxl:ml-24 xl:ml-24 sxl:ml-24 lg:ml-0 md:ml-0 sm:ml-0 xd:ml-0  '>
          <div className="!w-[100%]   h-auto flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-col md:flex-col sm:flex-col xd:flex-col   items-start justify-start gap-[8px] ">
            <div className=' xxl:!w-[80%] bxl:!w-[80%] xl:!w-[80%] sxl:!w-[80%] lg:!w-full md:!w-full sm:!w-full xd:!w-full flex flex-col justify-start items-start gap-[2rem]'>
              <div className='flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-col sm:flex-col xd:flex-col justify-start items-start w-[100%]'>
                <div className="!bg-black-900_19 rounded-[1rem] h-full xxl:w-[80%] bxl:w-[80%] xl:w-[80%] sxl:w-[80%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%">
                  <TradingViewWidget />
                </div>
                <div className='p-1 xxl:w-[20%] bxl:w-[20%] xl:w-[20%] sxl:w-[20%] lg:w-[100%] md:w-[100%] sm:w-[100%] xd:w-[100%] '>
                  <OrderBook />
                </div>
              </div>
              <AllTrades />
            </div>
            <div className=' !sticky !right-0 !top-24  xxl:!w-[20%] bxl:!w-[20%] xl:!w-[20%] sxl:!w-[20%] lg:!w-full md:!w-full sm:!w-full xd:!w-full flex flex-col'>
              <BuySell
                handleWalletConnect={handleWalletConnect}
              />
            </div>
          </div>
          <br />
        </div>
        {isConnectWalletisClicked && <ConnectWallet handleClose={handleCloseWalletConnect} />}
      </>}
    </>
  );
};

export default TradeFivePage;
