/* eslint-disable no-useless-escape */
import BigNumber from 'bignumber.js'
import { NetworkNames, initializeSdkGateway } from '@kanalabs/mirai';
import { AptosAccount, BCS, HexString } from 'aptos';
import axios from 'axios'
import { client } from './Constants';
const TEN = new BigNumber(10);

export const toDecimalPriceCalculate = ({
  price,
  lotSize,
  tickSize,
  baseCoinDecimals,
  quoteCoinDecimals,
}: {
  price: BigNumber;
  lotSize: BigNumber;
  tickSize: BigNumber;
  baseCoinDecimals: BigNumber;
  quoteCoinDecimals: BigNumber;
}) => {
  const lotsPerUnit = TEN.exponentiatedBy(baseCoinDecimals).div(lotSize);
  const pricePerLot = price
    .multipliedBy(tickSize)
    .div(TEN.exponentiatedBy(quoteCoinDecimals));
  return pricePerLot.multipliedBy(lotsPerUnit);
};

export const toDecimalSizeCalulate = ({
  size,
  lotSize,
  baseCoinDecimals,
}: {
  size: BigNumber;
  lotSize: BigNumber;
  baseCoinDecimals: BigNumber;
}) => {
  try {
    const val = size && lotSize && baseCoinDecimals && size.multipliedBy(lotSize).div(TEN.exponentiatedBy(baseCoinDecimals))
    return val;
  } catch (e) {
    console.log()
    return 0
  }

};

export const isObjectNonEmpty = (data: any): boolean => {
  return data && typeof data == 'object' && Object.keys(data).length > 0 ? true : false;
};

export const onTokenAmountChange = (e: any) => {
  const value: any = e;
  // only accept digit . and numbers
  if (
    /^[0-9\.]*$/gm.test(value) &&
    ((value.match(/[\.,]/gm) && value.match(/^[0-9]{0,9}(\.|,)?[0-9]{0,8}$/gm)) ||
      (!value.match(/[\.,]/gm) &&
        value.match(/^[0-9]{0,9}$/gm) &&
        (!value.match(/\./gm) || value.match(/\./gm)?.length <= 1) &&
        (!value.match(/,/gm) || value.match(/,/gm)?.length <= 1)))
  ) {
    // replace duplication if needed
    const amount = value
      .replace(/\.+/gm, '.')
      .replace(/,+/gm, ',')
      .replace(/^0+/gm, '0')
      // if first character is . then replace them with 0.
      .replace(/^\./, '0.');
    return amount
  }
  return ""
};

export function getUiAmount(number: number, tokenDecimal: number) {
  return number * Math.pow(10, tokenDecimal);
}

export function getUiAmountReverse(number: number, tokenDecimal: number) {
  return number / Math.pow(10, tokenDecimal);
}

export const preventPasteNegativeNumber = (e: any) => {
  const clipboardData = e.clipboardData || (window as any).clipboardData;
  const value = clipboardData.getData("text");
  if (!value || value.includes("-")) {
    e.preventDefault();
  } else {
    const pastedData = parseFloat(value);
    if (pastedData < 0) {
      e.preventDefault();
    }
  }
};

export const getPercentageAmount = (percentage: any, value: any, decimals: any) => {
  let amount = "";
  if (percentage === "25%") {
    amount = ((value / 100) * 25).toFixed(4);
    return amount

  } else if (percentage === "50%") {
    amount = ((value / 100) * 50).toFixed(4);
    return amount

  } else if (percentage === "Max") {
    const maxValue = getUiAmount(value, decimals)
    const ui = getUiAmountReverse(maxValue - getUiAmount(0.001, decimals), decimals);
    if(Math.sign(ui) === 1){
      return ui.toFixed(4)
    }
    return ""
  } else if (percentage === "Clear") {
    return amount
  }
};

export const handleInitWallet = async (web3Instance: any) => {
  try {
    const privateKey = await web3Instance.provider.request({
      method: "private_key",
    });
    const formatedKey = new Uint8Array(
      privateKey.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16)))
    const wallet = new AptosAccount(formatedKey);
    const address = wallet.address().hex();
    return { error: false, wallet: wallet, address: address }
  } catch (e) {
    return { error: true, wallet: "", address: "" }
  }
};

export const handleAddToWaitlist = async (address: string) => {
  try {
    const gas_usage_limit = 100000000;
    const url = `${process.env.REACT_APP_BASE_URL}/add-to-whitelist`;
    const data = {
      address,
      gas_usage_limit: gas_usage_limit,
    };
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "pay-key": process.env.REACT_APP_PAYMASTER_SPONSER_API_KEY as string,
      },
    });
    if (response.status === 201) {
      await initAccount(address)
      return { waitlistError: false, response: 'whitelisted' }
    }
    return { waitlistError: true, response: '' }
  } catch (e: any) {
    return { waitlistError: true, response: '' }
  }
};

export const delay = (ms: any) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export const handleSignWeb3AuthTransaction = async (sdk: any, payload: any) => {
  try {
    const feePayerTxn = await client.generateFeePayerTransaction(
      sdk.address(),
      payload,
      process.env.REACT_APP_FEE_PAYER
    );
    const senderAuth = await client.signMultiTransaction(sdk, feePayerTxn);
    const serializer = new BCS.Serializer();
    feePayerTxn.serialize(serializer);
    senderAuth.serialize(serializer);
    const serializerdata = serializer.getBytes();
    const hexStringData = HexString.fromUint8Array(serializerdata).toString();
    const stringifiedData = JSON.stringify(hexStringData, null, 3);
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/pay-gas`, stringifiedData, {
      headers: {
        "Content-Type": "application/json",
        "pay-key": process.env.REACT_APP_PAYMASTER_SPONSER_API_KEY as string,
      },
    });
    if (res.data.code === 200) {
      return { error: false, hash: res.data.hash, status: res.data.vm_status }
    }else if(res.data.code === 500){
      return { error: false, hash: res?.data?.error?.transaction?.hash, status: res?.data?.error?.transaction?.vm_status }
    }
    return { error: true, hash: "", status : "" }
  } catch (e) {
    return { error: true, hash: "", status: "" }
  }
}



export const initAccount = async (address: string) => {
  try {
    const acc = await client.getAccount(address);
    return { error: true, hash: "" }
  } catch (e: any) {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/init-account`;
      const data = {
        address,
      };
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "pay-key": process.env.REACT_APP_PAYMASTER_SPONSER_API_KEY as string,
        },
      });
      if (res.data.code === 200) {
        return { error: false, hash: res.data.hash }
      }
      return { error: true, hash: "" }
    } catch (e: any) {
      return { error: true, hash: "" }
    }
  }
};




