import React, { useEffect, useState } from 'react';
import down_arrow from '../assets/icons/select_downarrow.svg';
import aptos from '../assets/icons/AptosLogo.svg';
import usdc from '../assets/icons/usdc.svg';
import { useStore } from '../store';
import { isObjectNonEmpty } from '../utils/helper';
import { getPayloadForTestUsdcFaucet } from '@kanalabs/trade';
import { useAptosContext } from '../providers/AptosWalletContext';
import ClickAwayListener from 'react-click-away-listener';

const DropdownMenu = () => {
    const { availableMarkets, isLoading: isLoadingFromStore } = useStore();
    const { signAndSubmitTransaction }: any = useAptosContext()
    const [markets, setMarkets] = useState<any>();
    const [isTokenSelected, setIsTokenSelected] = useState(false);
    const handleTokenSelect = () => {
        if(isLoadingFromStore) return
        setIsTokenSelected(!isTokenSelected);
    };

    useEffect(() => {
        if (availableMarkets.length > 0) {
            const market3Response = availableMarkets.filter((market: any) => market.marketId === 3)
            if (isObjectNonEmpty(market3Response)) {
                setMarkets(market3Response)
            }
        }
    }, [availableMarkets])

    const selectedTokenMethod = (e: any) => {
        setIsTokenSelected(false);
    };

    const handleAddFaucet = async () => {
        const payload = getPayloadForTestUsdcFaucet()
        const sign = await signAndSubmitTransaction(payload)
    }
    const handleClickAway = () => {
        isTokenSelected && setIsTokenSelected(false);
    }
    return (
        <div className='w-full '>
            <div
                className={`trade_select ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} flex flex-row justify-between items-center  w-full`}
                onClick={handleTokenSelect}
                onChange={selectedTokenMethod}
            >
                <div className={`h-[4rrem] ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'} !bg-transparent  !shadow-none !font-inter flex flex-row gap-6   items-center justify-start  px-6 py-1  !w-full `}>
                    <div className="!bg-transparent  flex flex-row justify-start !items-center w-[10rem] !lg:w-full !md:w-1/2 sm:!w-full sm:pl-3 sm:!mt-1">
                        <img className="!bg-transparent  h-6  my-auto z-[14]  rounded-[50%] w-6" src={aptos} alt="usdc" />
                        <img
                            className="!bg-transparent  h-6 ml-[-0.625rem] z-[12]   my-auto  rounded-[50%] w-6"
                            src={usdc}
                            alt="matic"
                        />
                        <span className="!bg-transparent  ml-5 text-base text-white-A700  xxl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1 xd:py-1">
                            {markets?.length > 0 && markets[0]?.market}
                        </span>
                    </div>
                </div>
                <div className="pr-5 !bg-transparent ">
                    <img src={down_arrow} alt="down arrow" className='!bg-transparent '/>
                </div>
            </div>
            {isTokenSelected && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div className='!bg-[#1D1E20]  !backdrop-blur-2xl !fixed !z-[999] mt-3 xxl:!w-[20%] bxl:!w-[22%] xl:!w-[20%] sxl:!w-[20%] lg:!w-[27%]  md:!w-[84%]  sm:!w-[84%] xd:!w-[80%]  rounded-[2rem] '>
                        {markets?.length > 0 && markets.map((market: any, key: number) => {
                            return <>
                                <div key={key} className=' !bg-transparent    !w-full'>
                                    <div
                                        className="h-[4rem] cursor-pointer rounded-[2rem] !font-inter flex flex-row gap-6   items-center justify-start  px-6 py-1 !bg-transparent  !w-full hover:bg-[rgba(0,249,169,0.1)]  hover:rounded-[2rem]"
                                        onClick={() => selectedTokenMethod(market)}
                                    >
                                        <div className=" flex !bg-transparent flex-row justify-start !items-center w-[10rem] !lg:w-full !md:w-1/2 sm:!w-full sm:pl-3 sm:!mt-1">
                                            <img className=" h-6 !bg-transparent  my-auto z-[14]  rounded-[50%] w-6" src={aptos} alt="matic" />
                                            <img className=" h-6 ml-[-0.625rem] !bg-transparent  z-[12]   my-auto  rounded-[50%] w-6" src={usdc} alt="usdc" />
                                            <span className="ml-5 text-base !bg-transparent  text-white-A700  xxl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-1 xd:py-1">
                                                {market.market}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    )
}

export default DropdownMenu