import { StateSlice, Store } from '../types';

export type AppSlice = {
  registeredMarkets: any;
  availableMarkets: any;
  marketDataInstance: any;
  needToUpdateGlobalTrades: boolean;
  orderBook: any;
  globalTrades: any;
  isLoading: boolean;
  balanceUpdate: boolean;
  aptosWeb3Instance: any;
  address: string;
  sdk: any;
  isWalletConnected: boolean;
  updateRegisteredMarkets(payload: any): void;
  updateAvailableMarkets(payload: any): void;
  updateMarketDataInstance(payload: any): void;
  updateNeedToUpdateGlobalTrades(payload: any) : void;
  updateOrderBook(payload: any) : void;
  updateGlobalTrades(payload: any): void;
  updateIsLoading(payload: any) : void;
  updateBalanceUpdate(payload: any): void;
  updateAptosWeb3Instance(payload: any): void;
  updateAddress(payload: any): void;
  updateSdk(payload: any): void;
  updateIsWalletConnected(payload: any): void;
};

export const createAppSlice: StateSlice<Store, AppSlice> = (set:any) => ({
    registeredMarkets: [],
    availableMarkets:[],
    marketDataInstance:{},
    needToUpdateGlobalTrades: true,
    orderBook:{},
    globalTrades:[],
    isLoading: false,
    balanceUpdate: true,
    aptosWeb3Instance: {},
    address:"",
    sdk:{},
    isWalletConnected: false,
    updateRegisteredMarkets(payload: AppSlice['registeredMarkets']) {
        set({ registeredMarkets: payload });
    },
    updateAvailableMarkets(payload: AppSlice['availableMarkets']) {
        set({ availableMarkets: payload });
    },
    updateMarketDataInstance(payload: AppSlice['marketDataInstance']) {
        set({ marketDataInstance: payload });
    },
    updateNeedToUpdateGlobalTrades(payload: AppSlice['needToUpdateGlobalTrades']) {
        set({ needToUpdateGlobalTrades: payload });
    },
    updateOrderBook(payload: AppSlice['orderBook']){
        set({orderBook: payload});
    },
    updateGlobalTrades(payload: AppSlice['globalTrades']){
        set({globalTrades: payload});
    },
    updateIsLoading(payload: AppSlice['isLoading']){
        set({isLoading: payload});
    },
    updateBalanceUpdate(payload: AppSlice['balanceUpdate']){
        set({balanceUpdate: payload});
    },
    updateAptosWeb3Instance(payload: AppSlice['aptosWeb3Instance']){
        set({aptosWeb3Instance: payload});
    },
    updateAddress(payload: AppSlice['address']){
        set({address: payload});
    },
    updateSdk(payload: AppSlice['sdk']){
        set({sdk: payload});
    },
    updateIsWalletConnected(payload: AppSlice['isWalletConnected']){
        set({isWalletConnected: payload});
    }
});
