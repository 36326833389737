import { Button, List } from '../components';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import history from '../assets/icons/history.svg';
import history_green from '../assets/icons/history_green.svg';
import globe_green from '../assets/icons/globe_green.svg';
import globe_wallet from '../assets/icons/globe_wallet.svg';
import { useTranslation } from 'react-i18next';
import Delete from '../assets/icons/delete.svg';
import { useStore } from '../store';
import { BUY, SELL, TradeTable, toDecimalPrice, toDecimalSize } from '@kanalabs/trade';
import { handleSignWeb3AuthTransaction, isObjectNonEmpty } from '../utils/helper';
import aptos from '../assets/icons/AptosLogo.svg';
import { useAptosContext } from '../providers/AptosWalletContext';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
import { client } from '../utils/Constants';
import Kanaloader from '../assets/kanaloader.json';
import Lottie from 'react-lottie-player';
import NewMessageNotification from '../components/NewMessageNotification';
const AllTrades = () => {
  const { t } = useTranslation();
  const { marketDataInstance, needToUpdateGlobalTrades, sdk, address, isWalletConnected, updateNeedToUpdateGlobalTrades, updateIsLoading, globalTrades: globalTradesStore, isLoading: isLoadingFromStore } = useStore();
  const { account, connected, signAndSubmitTransaction }: any = useAptosContext();
  const [isImageOne, setIsImageOne] = useState(false);
  const [isImageTwo, setIsImageTwo] = useState(false);
  const [globalTrades, setGlobalTrades] = useState<TradeTable[]>();
  const [yourOrders, setYourOrders] = useState<any>();
  const [lastUpdated, setLastUpdated] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTabClick = () => {
    setIsImageTwo(false);
    setIsImageOne(false);
  };

  const handleTabClick2 = () => {
    setIsImageOne(true);
    setIsImageTwo(true);
  };

  const handleGetGlobalTrades = async () => {
    if (isObjectNonEmpty(marketDataInstance)) {
      try {
        setLastUpdated(globalTradesStore.lastUpdated)
        const pairInfo = await marketDataInstance?.getTradePairInfo();
        const uniqueSet = new Set();
        const result: TradeTable[] = [];
        globalTradesStore?.data.forEach((obj: any) => {
          const propertyValue = obj['txn_version'];
          if (!uniqueSet.has(propertyValue)) {
            uniqueSet.add(propertyValue);
            result.push(obj);
          }
        });
        const sortedTrades = [...result].sort((a, b) => b.txn_version - a.txn_version);
        sortedTrades.map((trade: any) => {
          const uiSize: any = toDecimalSize({
            size: new BigNumber(trade.size),
            lotSize: marketDataInstance.market.lotSize,
            baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
          })
          const uiPrice: any = toDecimalPrice({
            price: new BigNumber(trade.price),
            lotSize: marketDataInstance.market.lotSize,
            tickSize: marketDataInstance.market.tickSize,
            baseCoinDecimals: new BigNumber(pairInfo.baseCoinInfo.decimals),
            quoteCoinDecimals: new BigNumber(pairInfo.quoteCoinInfo.decimals),
          })
          trade.uiSize = uiSize;
          trade.uiPrice = uiPrice
        })
        setGlobalTrades(sortedTrades);
      } catch (e) {
        console.log();
      }
    }
  };

  const handleGetUserTrades = async () => {
    const openOrders = await marketDataInstance.getOpenOrders(connected ? account.address : address, 'open');
    const tradePairInfo = await marketDataInstance.getTradePairInfo();
    if (isObjectNonEmpty(openOrders)) {
      const orders: any = [];
      openOrders.bids.length > 0 &&
        openOrders.bids.map((bid: any, key: number) => {
          const uiSize: any = toDecimalSize({
            size: new BigNumber(bid.size),
            lotSize: marketDataInstance.market.lotSize,
            baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
          });
          const uiPrice: any = toDecimalPrice({
            price: new BigNumber(bid.price),
            lotSize: marketDataInstance.market.lotSize,
            tickSize: marketDataInstance.market.tickSize,
            baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
            quoteCoinDecimals: new BigNumber(tradePairInfo.quoteCoinInfo.decimals),
          });
          bid.counterString = bid.counter.toString();
          bid.priceString = uiPrice.toString();
          bid.sizeString = uiSize.toString();
          bid.orderType = 'BID';
          bid.selected = false;
          orders.push(bid);
        });
      openOrders.asks.length > 0 &&
        openOrders.asks.map((ask: any, key: number) => {
          const uiSize: any = toDecimalSize({
            size: new BigNumber(ask.size),
            lotSize: marketDataInstance.market.lotSize,
            baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
          });
          const uiPrice: any = toDecimalPrice({
            price: new BigNumber(ask.price),
            lotSize: marketDataInstance.market.lotSize,
            tickSize: marketDataInstance.market.tickSize,
            baseCoinDecimals: new BigNumber(tradePairInfo.baseCoinInfo.decimals),
            quoteCoinDecimals: new BigNumber(tradePairInfo.quoteCoinInfo.decimals),
          });
          ask.counterString = ask.counter.toString();
          ask.priceString = uiPrice.toString();
          ask.sizeString = uiSize.toString();
          ask.orderType = 'ASK';
          ask.selected = false
          orders.push(ask);
        });
      const sortedOrders = [...orders].sort((a, b) => parseFloat(b.counterString) - parseFloat(a.counterString));
      setYourOrders(sortedOrders);
      updateNeedToUpdateGlobalTrades(false);
    }

  };

  useEffect(() => {
      handleGetUserTrades();
  }, [isWalletConnected, connected, needToUpdateGlobalTrades]);


  useEffect(() => {
    if (connected === false && isWalletConnected === false) {
      setYourOrders([]);
    }
  }, [connected, isWalletConnected]);

  useEffect(() => {
    handleGetGlobalTrades();
  }, [globalTradesStore])

  const handleCloseTrade = async (order: any) => {
    try {
      if (isLoadingFromStore) return
      const newArray = [...yourOrders]
      updateIsLoading(true)
      newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = true : item.selected = false)
      setYourOrders(newArray)
      const closeOrder = marketDataInstance.cancelOrder(order.orderType === 'ASK' ? SELL : BUY, order.marketOrderId.toBigInt().toString());
      let hash = ""
      let res = ""
      if (connected) {
        const hashResponse = await signAndSubmitTransaction(closeOrder);
        await client.waitForTransaction(hashResponse.hash)
        hash = hashResponse.hash
        const response = await client.getTransactionByHash(hash);
        res = response.vm_status;
      } else if (isWalletConnected) {
        const { error, hash: transactionHash, status } = await handleSignWeb3AuthTransaction(sdk, closeOrder)
        if (error) {
          const newArray = [...yourOrders]
          updateIsLoading(false)
          newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
          setYourOrders(newArray)
          return toast(<NewMessageNotification link={transactionHash} type={"error"} message='Transaction Failed' />);
        }
        hash = transactionHash
        res = status;
      }
      if (res === 'Executed successfully') {
        const newArray = [...yourOrders]
        updateIsLoading(false)
        newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
        setYourOrders(newArray)
        toast(<NewMessageNotification link={hash} type={"success"} message='Order Cancelled Successfully' />);
        updateNeedToUpdateGlobalTrades(true)
        return;
      }
      const newArrays = [...yourOrders]
      updateIsLoading(false)
      newArrays.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
      setYourOrders(newArrays)
      toast(<NewMessageNotification link={hash} type={"error"} message='Please try again' />);
    } catch (e: any) {
      const newArray = [...yourOrders]
      updateIsLoading(false)
      newArray.find((item) => item.marketOrderId === order.marketOrderId ? item.selected = false : item.selected = false)
      setYourOrders(newArray)
      if (e.name === 'Rejected') {
        toast(<NewMessageNotification link={'none'} type={"error"} message='User Rejected' />);
        return;
      }
      toast(<NewMessageNotification link={"none"} type={"error"} message='Please try again' />);
    }
  };

  useEffect(() => {
    updateIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <div className="md:my-5 sm:my-5 xd:my-5 sm:mx-0 xd:mx-0 p-[0rem_1rem_1rem_1rem] flex  flex-row gap-4 items-center justify-between   !w-full md:flex-col sm:flex-col xd:flex-col">
        <Tabs className=" flex flex-col items-start justify-center w-full rounded-[1.5rem] shadow-bs  ">
          <div className="flex  flex-row items-center justify-between w-full md:flex-col sm:flex-col xd:flex-col">
            <TabList className="flex flex-row justify-start items-center w-full xxl:justify-start bxl:justify-start xl:justify-start sxl:justify-start lg:justify-start md:justify-start sm:justify-start xd:justify-between">
              <Tab
                onClick={handleTabClick}
                className="your_trade text-center  !justify-center !items-center xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-row  sm:!flex-row xd:!flex-row xd:w-auto sm:w-auto sm:!text-[0.75rem] "
              >
                {isImageOne ? (
                  <img className="h-4 mr-2 my-1 bg-transparent" src={history} alt="history" />
                ) : (
                  <img className="h-4 mr-2 my-1 bg-transparent" src={history_green} alt="history_green" />
                )}
                {t('your_trades')}
              </Tab>
              <Tab
                className="your_trade text-center  !justify-center !items-center md:!w-auto xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row  md:!flex-row  sm:!flex-row xd:!flex-row xd:w-auto sm:w-auto sm:!text-[0.75rem] "
                onClick={handleTabClick2}
              >
                {isImageTwo ? (
                  <img className="h-4 mr-2 my-1 bg-transparent" src={globe_green} alt="globe_wallet" />
                ) : (
                  <img className="h-4 mr-2 my-1 bg-transparent" src={globe_wallet} alt="globe_green" />
                )}
                {t('global_trades')}
              </Tab>
            </TabList>
          </div>
          <br />
          <TabPanel className="w-full">
            <List className="flex flex-col gap-2 items-start w-full   overflow-y-auto " orientation="vertical">
              {yourOrders && yourOrders.length > 0 ? (
                yourOrders?.map((trade: any, key: number) => {
                  return (
                    <div
                      key={key}
                      className="bg-[#17181A] backdrop-blur-lg flex flex-col gap-4 items-start justify-center  my-0 p-4 rounded-[1rem] w-full"
                    >
                      <div className="flex !bg-transparent xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:!flex-col sm:flex-col xd:flex-col  justify-between items-center w-full">
                        <div className="flex !bg-transparent xxl:flex-[0.45] bxl:flex-[0.45] xl:flex-[0.45] sxl:flex-[0.45] lg:flex-[0.45] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row xd:flex-row gap-2 items-center justify-between w-full py-1">
                          <div className="flex !bg-transparent flex-[0.5] flex-col gap-1 items-start justify-center w-auto">
                            <div className="text-white-A700_99 !bg-transparent text-xs w-auto">{t('asset')}</div>
                            <div className="flex flex-row gap-1 items-center !bg-transparent justify-start w-auto">
                              <div className="text-sm text-white-A700_e5 w-auto !bg-transparent">eAPT/eUSDC</div>
                              <Button className="bg-cyan-A200_19 cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center text-cyan-A200_66">
                                {trade?.orderType}
                              </Button>
                            </div>
                          </div>
                          <div className="flex !bg-transparent flex-1 flex-[0.5] flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start justify-center w-full">
                            <div className="text-white-A700_99 !bg-transparent text-xs w-auto">{t('quantity')}</div>
                            <div className="text-sm text-white-A700_e5 w-auto !bg-transparent">{trade?.sizeString}</div>
                          </div>
                        </div>
                        <div className="flex !bg-transparent xxl:flex-[0.55] bxl:flex-[0.55] xl:flex-[0.55] sxl:flex-[0.55] lg:flex-[0.55] md:!flex-[1]   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row gap-2 items-center justify-between w-full sm:pt-4 py-1">
                          <div className="flex !bg-transparent flex-1 flex-[0.5] flex-col gap-1 xxl:items-start bxl:items-start xl:items-start sxl:items-start lg:items-start md:items-start sm:items-start xd:items-start justify-center w-full">
                            <div className="text-white-A700_99 !bg-transparent text-xs w-auto">{t('limit_price')}</div>
                            <div className="text-sm text-white-A700_e5 !bg-transparent w-auto">{trade?.priceString}</div>
                          </div>
                          <div className='flex flex-row flex-[0.5] gap-2'>
                            <div className="flex flex-1   flex-col gap-1 xxl:!items-start bxl:!items-start xl:!items-start sxl:!items-start lg:!items-start md:!items-start sm:!items-start xd:!items-start justify-center w-full">
                              <div className="text-white-A700_99 text-xs w-auto">Total</div>
                              <div className="text-sm text-white-A700_e5 w-auto">
                                {trade?.priceString * trade?.sizeString}
                              </div>
                            </div>
                            {trade?.selected ? <Lottie
                              loop
                              animationData={Kanaloader}
                              play
                              className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                            /> :
                              <div
                                onClick={() => handleCloseTrade(trade)}
                                className={`flex  ${isLoadingFromStore ? 'cursor-not-allowed' : 'cursor-pointer'}  bg-[rgba(255,85,85,0.10)]  h-auto rounded-[0.5rem] p-[1rem]  flex-row justify-center items-center !w-auto`}
                              >
                                <img src={Delete} alt="Delete" />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="bg-white-A700_0a backdrop-blur-lg flex flex-col gap-4 items-start justify-center  my-0 p-4 rounded-[1rem] w-full">
                    <div className="!bg-transparent flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:!flex-col sm:flex-col xd:flex-col  justify-between items-center w-full">
                      <div className="!bg-transparent flex xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.4] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row xd:flex-row gap-2 items-center justify-between w-full py-1">
                        <div className="flex flex-[0.5] !bg-transparent flex-col gap-1 items-start justify-start w-auto">
                          <div className="text-white-A700_99 text-xs w-auto !bg-transparent">{t('asset')}</div>
                        </div>
                        <div className="flex flex-1 flex-[0.5] flex-col !bg-transparent gap-1 xxl:!items-end bxl:!items-end xl:!items-end sxl:!items-end lg:!items-end md:!items-start sm:!items-start justify-between w-full">
                          <div className="text-white-A700_99 text-xs w-auto !bg-transparent">{t('quantity')}</div>
                        </div>
                      </div>

                      <div className="flex !bg-transparent xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.6] lg:flex-[0.6] md:!flex-[1]   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row gap-2 items-center justify-between w-full sm:pt-4 py-1">
                        <div className="flex !bg-transparent flex-1 md:flex-[0.5] flex-col gap-1 xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-start sm:items-start xd:items-start justify-center w-full">
                          <div className="text-white-A700_99 !bg-transparent text-xs w-auto">{t('limit_price')}</div>
                        </div>
                        <div className="flex flex-1 md:flex-[0.5] !bg-transparent flex-col gap-1 xxl:!items-end bxl:!items-end xl:!items-end sxl:!items-end lg:!items-end md:!items-start justify-center w-full">
                          <div className="text-white-A700_99 text-xs w-auto !bg-transparent">Total</div>
                        </div>
                      </div>
                    </div>
                    <div className="text-sm !bg-transparent py-4 text-white-A700_e5 w-full flex flex-row justify-center items-center  text-center">
                      No open orders now!
                    </div>
                  </div>
                </>
              )}
            </List>
          </TabPanel>
          <TabPanel className="w-full">
            <List className="flex flex-col gap-2 items-start w-full  overflow-y-auto  " orientation="vertical">
              {globalTrades && globalTrades.length > 0 ? (
                globalTrades.map((trade: any, key: number) => {
                  return (
                    <div
                      key={key}
                      className="bg-[#17181A]   backdrop-blur-lg flex flex-col gap-4 items-start justify-center  my-0 p-4 rounded-[1rem] w-full"
                    >
                      <div className="!bg-transparent flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:!flex-col sm:flex-col xd:flex-col  justify-between items-center w-full">
                        <div className="!bg-transparent flex xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.4] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row xd:flex-row gap-2 items-center justify-between w-full py-1">
                          <div className="!bg-transparent flex  flex-[0.5] flex-col gap-1 items-start justify-center w-auto">
                            <div className="!bg-transparent text-white-A700_99 text-xs w-auto">{t('asset')}</div>
                            <div className="!bg-transparent flex flex-row gap-1 items-center justify-start w-auto">
                              <div className="!bg-transparent text-sm text-white-A700_e5 w-auto">
                                <img className="h-4 mr-2 my-1 !bg-transparent " src={aptos} alt="globe_green" />
                              </div>
                              <Button className="bg-[rgba(0,255,255,0.10)] cursor-pointer font-bold leading-[normal] min-w-[2.375rem] py-[0.188rem] rounded text-[0.625rem] text-center text-[rgba(0,255,255,0.40)]">
                                {trade?.maker_side ? 'SELL' : 'BUY '}
                              </Button>
                            </div>
                          </div>
                          <div className="!bg-transparent flex flex-1 flex-[0.5] flex-col gap-1 xxl:!items-end bxl:!items-end xl:!items-end sxl:!items-end lg:!items-end md:!items-start sm:!items-start justify-center w-full">
                            <div className="!bg-transparent text-white-A700_99 text-xs w-auto">{t('quantity')}</div>
                            <div className="!bg-transparent text-sm text-white-A700_e5 w-auto">{trade?.uiSize?.toString()}</div>
                          </div>
                        </div>
                        <div className="!bg-transparent flex xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.6] lg:flex-[0.6] md:!flex-[1]   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row gap-2 items-center justify-between w-full sm:pt-4 py-1">
                          <div className="!bg-transparent flex flex-1  flex-[0.5] flex-col gap-1 xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-start sm:items-start xd:items-start justify-center w-full">
                            <div className="!bg-transparent text-white-A700_99 text-xs w-auto">Executed Price</div>
                            <div className="!bg-transparent text-sm text-white-A700_e5 w-auto">{trade?.uiPrice?.toString()}</div>
                          </div>
                          <a
                            href={`https://explorer.aptoslabs.com/txn/${trade?.txn_version}?network=testnet`}
                            target="blank"
                            className=" !bg-transparent flex flex-1 flex-[0.5] flex-col gap-1 xxl:!items-end bxl:!items-end xl:!items-end sxl:!items-end lg:!items-end md:!items-start sm:items-start xd:items-start justify-center w-full"
                          >
                            <div className="!bg-transparent text-white-A700_99 text-xs w-auto">Transaction Id</div>
                            <div className="!bg-transparent text-sm text-[#2ED3B7] w-auto">{trade?.txn_version}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="bg-white-A700_0a   backdrop-blur-lg flex flex-col gap-4 items-start justify-center  my-0 p-4 rounded-[1rem] w-full">
                    <div className="!bg-transparent flex xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:!flex-[1] md:!flex-col sm:flex-col xd:flex-col  justify-between items-center w-full">
                      <div className="!bg-transparent flex xxl:flex-[0.4] bxl:flex-[0.4] xl:flex-[0.4] sxl:flex-[0.4] lg:flex-[0.4] md:!flex-[1]  xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row xd:flex-row gap-2 items-center justify-between w-full py-1">
                        <div className="!bg-transparent flex flex-[0.5] flex-col gap-1 items-start justify-center w-auto">
                          <div className="!bg-transparent text-white-A700_99 text-xs w-auto">{t('asset')}</div>
                        </div>
                        <div className="!bg-transparent flex flex-1 flex-[0.5] flex-col gap-1 xxl:!items-end bxl:!items-end xl:!items-end sxl:!items-end lg:!items-end md:!items-start sm:!items-start justify-center w-full">
                          <div className="!bg-transparent text-white-A700_99 text-xs w-auto">{t('quantity')}</div>
                        </div>
                      </div>
                      <div className="!bg-transparent flex xxl:flex-[0.6] bxl:flex-[0.6] xl:flex-[0.6] sxl:flex-[0.6] lg:flex-[0.6] md:!flex-[1]   xxl:flex-row bxl:flex-row xl:flex-row sxl:flex-row lg:flex-row md:flex-row sm:flex-row gap-2 items-center justify-between w-full sm:pt-4 py-1">
                        <div className="!bg-transparent flex flex-1 flex-[0.5] flex-col gap-1 xxl:items-end bxl:items-end xl:items-end sxl:items-end lg:items-end md:items-start sm:items-start xd:items-start justify-center w-full">
                          <div className="!bg-transparent text-white-A700_99 text-xs w-auto">Executed Price</div>
                        </div>
                        <div className="!bg-transparent flex flex-1 flex-[0.5] flex-col gap-1 xxl:!items-end bxl:!items-end xl:!items-end sxl:!items-end lg:!items-end md:!items-start justify-center w-full">
                          <div className="!bg-transparent text-white-A700_99 text-xs w-auto">Transaction Id</div>
                        </div>
                      </div>
                    </div>
                    <div className="!bg-transparent text-sm py-4 text-white-A700_e5 w-full flex flex-row justify-center items-center  text-center">
                      {/* <ColorRing
                        visible={true}
                        height="25"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#00000', '#00000', '#00F9A9', '#00000', '#00000']}
                      /> */}
                      <Lottie
                        loop
                        animationData={Kanaloader}
                        play
                        className="w-[6.5rem] h-[1.5rem] flex justify-center items-center"
                      />
                    </div>
                  </div>
                </>
              )}
            </List>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default AllTrades;
